import update from 'immutability-helper'

import ActionTypes from './actionTypes'

let initialState = {
	'top-left': {},
	'top-right': {},
	'top-center': {},
	'bottom-left': {},
	'bottom-right': {},
	'bottom-center': {}
}

const Notifications = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.ADD_NOTIFICATION:
			return update(state, {
				[action.payload.position]: {
					$merge: {
						[action.payload.id]: action.payload
					}
				}
			})
		case ActionTypes.EDIT_NOTIFICATION:
			return update(state, {
				[action.payload.index]: {
					[action.payload.field]: {
						$set: [action.payload.value]
					}
				}
			})
		case ActionTypes.REMOVE_NOTIFICATION:
			return update(state, {
				[action.payload.position]: {
					// [action.payload.id]: {
					$unset: [action.payload.id]
					// }
				}
			})
		case ActionTypes.CLEAR_ALL:
			return initialState
		default:
			return state
	}
}

export default Notifications
