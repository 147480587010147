import ActionTypes from './actionTypes'

const initialState = {
	id: null,
	loading: false,
	isSuperuser: '',
	email: '',
	firstName: '',
	lastName: '',
	phone: '',
	isEmailVerified: '',
	isPhoneVerified: '',
	token: window.localStorage.getItem('token'),
	avatar: ''
}

export default (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.REQUEST_PROFILE:
			return { ...state, loading: true }
		case ActionTypes.ERROR_REQUEST_PROFILE:
			return { ...state, loading: false }
		case ActionTypes.SET_USER:
			return { ...state, ...action.payload, loading: false }
		case ActionTypes.SET_TOKEN:
			return { ...state, token: action.payload }
		case ActionTypes.EDIT:
			return { ...state, [action.payload.field]: action.payload.value }
		case ActionTypes.RESET:
			return {
				id: '',
				isSuperuser: '',
				email: '',
				firstName: '',
				lastName: '',
				isPhoneVerified: '',
				phone: '',
				isEmailVerified: '',
				token: null
			}
		default:
			return state
	}
}
