import React from 'react'

import EmailNotVerify from 'containers/Notifications/EmailNotVerify'
import PhoneNotVerify from 'containers/Notifications/PhoneNotVerify'

import client from 'apollo/'
import { ME } from 'apollo/queries'

import { createAction } from 'helpers/createAction'

import { edit as editBase } from 'store/Base/actions'
import { addNotification } from 'store/Notifications/actions'

import ActionTypes from './actionTypes'

export const reset = () => createAction(ActionTypes.RESET)

export const setToken = (payload = null) => createAction(ActionTypes.SET_TOKEN, payload)

export const setUser = (payload) => createAction(ActionTypes.SET_USER, payload)
export const edit = ({ field, value }) => createAction(ActionTypes.EDIT, { field, value })

export const requestProfile = () => (dispatch, store) => {
	const { emailConfirm, phoneConfirm } = store().Base
	const Profile = store().Profile

	// If user didn`t loggined - dispatch message and stop function
	if (!Profile.token) {
		dispatch({ type: ActionTypes.REQUEST_PROFILE_CANCELED, message: 'User didn`t loggined' })
		return false
	}

	dispatch({ type: ActionTypes.REQUEST_PROFILE }) // loading: true
	client
		.query({
			query: ME,
			fetchPolicy: 'network-only'
		})
		.then((resp) => {
			const profile = resp.data.me
			if (!profile.isEmailVerified && !emailConfirm) {
				dispatch(editBase({ field: 'emailConfirm', value: true }))
				dispatch(
					addNotification({
						id: Math.random() + '',
						content: <EmailNotVerify />,
						type: 'error',
						closeOnClick: true
					})
				)
			}
			if (!profile.isPhoneVerified && !phoneConfirm) {
				dispatch(editBase({ field: 'phoneConfirm', value: true }))
				dispatch(
					addNotification({
						id: Math.random() + '',
						content: <PhoneNotVerify />,
						type: 'error',
						closeOnClick: true
					})
				)
			}
			dispatch(setUser(profile))
		})
		.catch((err) => {
			dispatch({ type: ActionTypes.ERROR_REQUEST_PROFILE, err })
		})
}
