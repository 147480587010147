export default {
	intro: {
		header: 'Biļešu serviss jūsu pasākumiem',
		analytics: 'Analytics un pārskati',
		tickets: 'Biļešu pārbaude un aktivizēšana',
		money: 'Izņemšana no kartes',
		notifications: 'Paziņojumi klientiem, izmantojot SMS',
		fb: 'Sinhronizēt ar grāmatu',
		promocode: 'Reklāmas kodi ar atlaidi'
	},
	howItWorks: {
		title: 'Kā tas darbojas?',
		items: [
			{
				header: 'Izveidojiet un pielāgojiet savus pasākumus',
				text:
					'Izvēlne ļauj izveidot notikumus un tos pārvaldīt. No pirmā acu uzmetiena var redzēt, vai pārdošana ir aktīva un pārdoto biļešu skaits. Varat arī pielāgot savu notikumu izskatu, lai tie atbilstu jūsu zīmolam, augšupielādējot baneru attēlus. ',
				list: [
					'Ātra reģistrācija 24/7',
					'Konti ar atšķirīgām piekļuves tiesībām',
					'Klientu paziņojums ar SMS'
				]
			},
			{
				header: 'Veicināt un reklamēt savus pasākumus',
				text:
					'Mūsu sistēma ļauj ērti izkraut dalībnieku datu bāzi par notikumiem, lai importētu pasta sistēmās, piemēram, MailChimp vai eSputnik.',
				list: [
					"Pievienot saiti 'iegādāties tagad' savā tiešsaistes reklāmā",
					'Elastīga sistēma reklāmas kodiem',
					'Lietot MailChimp integrāciju e-pasta reklāmai'
				]
			},
			{
				header: 'Pārvaldiet savus klientus un apskatiet rezultātus',
				text:
					'Dažādas pārskatu opcijas palīdzēs analizēt pārdošanu. Arī ar mūsu pakalpojumu palīdzību jūs varat importēt un eksportēt klientus. ',
				list: [
					'Dažādu pārskatu opcijas',
					'Importēt un eksportēt klientus',
					'Skatiet, kā klienti jūs atrodat mūsu pārskatos'
				]
			}
		]
	},
	pricing: {
		title: 'Cenu politika',
		items: [
			{
				header: 'Bezmaksas pasākuma organizētājiem',
				text: 'Nav iestatīšanas izmaksu Nav ikmēneša abonementa'
			},
			{
				header: 'Bezmaksas biļetes bezmaksas pasākumiem',
				text:
					'Izmantojiet jebkuras funkcijas, lai notiktu notikumi bez maksas. Ja pasākums ir bezmaksas, mēs arī 😁 '
			},
			{
				header: 'Zema komisija par apmaksātiem pasākumiem',
				text: 'Noņemts 5% - maksājumu apstrādes sistēmas pasūtīšana un biļešu servisa pasūtīšana'
			}
		]
	},
	aboutUs: {
		title: 'Labākais notikumu palīgs',
		text:
			'Teeko ir platforma biļešu pārdošanas organizēšanai, kurā mēs ņēmām vērā labāko pieredzi gan nelielu, gan diezgan lielu pasākumu organizēšanā (1500+ cilvēki). Jūs, kā organizētāji vai biļešu pircēji, esat mūsu ģimene. Mēs pastāvīgi pilnveidojam pakalpojumu un vienmēr esam priecīgi dzirdēt par jūsu idejām un vajadzībām.'
	},
	advantages: {
		title: 'Ieguvumi',
		header: 'Ieguvumi no darba ar mums',
		items: [
			{
				header: 'Atbalsts jums visos pakalpojuma jautājumos',
				text:
					'Mēs kopā ar organizatoru esam iesaistījušies no biļešu pārdošanas līdz pēdējam apmeklētājam.'
			},
			{
				header: 'Ātrā palaišana',
				text: 'Parasti uzsākšana ir iespējama 1 darba dienā'
			},
			{
				header: 'Ātrā procentuālā daļa, kas ir biļešu pārdošana',
				text: 'Organizatoram nav jāgaida, līdz beigsies pasākums, lai saņemtu savu naudu'
			}
		]
	},
	startCooperate: {
		header: 'Sāciet pārdot biļetes ar teeko un nopelnīt vairāk naudas!',
		btn: 'Sākt tagad'
	}
}
