export default {
	title: 'Search results',
	filters: 'Filters',
	apply: 'Apply',
	emptyResult: 'The search has not given any results',
	reset: 'Reset',
	dateFilter: {
		label: 'Select date / period:',
		range: {
			placeholder: 'Select a period',
			value: 'from {{start}} - to {{end}}'
		},
		today: 'Today',
		tomorrow: 'Tomorrow',
		thisWeek: 'This Week'
	},
	priceFilter: {
		label: 'Choose price category',
		free: 'free',
		inputPrice: 'Enter price',
		min: 'Min.',
		price: 'Max.'
	},
	sort: {
		label: 'Sort',
		NEW: 'New first',
		PRICE: 'First expensive',
		PRICE_DESC: 'Cheap first',
		START: 'First coming',
		START_DESC: 'Future first'
	},
	objectInPage: {
		label: 'Show on page:',
		value: '{{value}} events'
	},
	pagination: {
		next: 'Next',
		prev: 'Previous',
		first: 'First',
		last: 'Last'
	}
}
