import { createAction } from 'helpers/createAction'

import ActionTypes from './actionTypes'

const generateDefaultConfig = () => ({
	id: +new Date(),
	content: null,
	autoClose: false,
	position: 'top-right',
	type: 'default',
	closeOnClick: true,
	hideProgressBar: false,
	className: null,
	onClose: null,
	onOpen: null,
	onUpdate: null,
	style: null
})

export const addNotification = (config) =>
	createAction(ActionTypes.ADD_NOTIFICATION, { ...generateDefaultConfig(), ...config })

export const removeNotification = ({ id, position }) =>
	createAction(ActionTypes.REMOVE_NOTIFICATION, { id, position })

export const editNotification = ({ index, field, value }) =>
	createAction(ActionTypes.EDIT_NOTIFICATION, { index, field, value })

export const clearAll = () => createAction(ActionTypes.CLEAR_ALL)
