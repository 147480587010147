import React from 'react'

export default function IconEmail({ className = '' }) {
	return (
		<svg
			className={className}
			width="15"
			height="11"
			viewBox="0 0 15 11"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.3534 3.54768C14.4625 3.46094 14.6248 3.54208 14.6248 3.67917V9.40078C14.6248 10.1422 14.0233 10.7437 13.2818 10.7437H1.64277C0.901343 10.7437 0.299805 10.1422 0.299805 9.40078V3.68197C0.299805 3.54208 0.459282 3.46374 0.571196 3.55047C1.19792 4.0373 2.02888 4.65562 4.88269 6.72883C5.47303 7.1597 6.46907 8.06621 7.4623 8.06061C8.46114 8.069 9.47676 7.14291 10.0447 6.72883C12.8985 4.65562 13.7267 4.0345 14.3534 3.54768ZM7.4623 7.1625C8.11141 7.17369 9.04589 6.34553 9.51593 6.00419C13.2287 3.30986 13.5113 3.07484 14.3674 2.40335C14.5297 2.27745 14.6248 2.0816 14.6248 1.87456V1.34297C14.6248 0.601538 14.0233 0 13.2818 0H1.64277C0.901343 0 0.299805 0.601538 0.299805 1.34297V1.87456C0.299805 2.0816 0.394932 2.27465 0.557207 2.40335C1.41335 3.07204 1.69593 3.30986 5.40868 6.00419C5.87872 6.34553 6.8132 7.17369 7.4623 7.1625Z"
				fill="#575757"
			/>
		</svg>
	)
}
