export const TABS = ['privateEntrepreneur', 'ooo', 'oo']

export const SCAN_TYPE = [
	'PASSPORT',
	'INN',
	'REGISTRY_REPORT',
	'FONDY_CONTRACT',
	'BANK_REPORT',
	'COMPANY_STATUTE',
	'DIRECTOR_PROTOCOL',
	'FINANCE_REPORT',
	'ORG_STRUCTURE'
]

export const LANGUAGES = ['en', 'uk', 'pl', 'lv', 'lt', 'ru' /* , 'he' */]

export const QUESTION_TYPE_TO_TEXT = {
	INPUT: 'event.registration.simpleText',
	TEXTAREA: 'event.registration.bigText',
	EMAIL: 'Email',
	URL: 'Url',
	PHONE_NUMBER: 'sign.phone',
	SELECT: 'event.registration.select',
	DROPDOWN: 'event.registration.dropdown'
}

export const ATTENDEE_STATUSES = {
	UNFINISHED: 'UNFINISHED',
	PAID: 'PAID',
	REGISTERED: 'REGISTERED',
	ERROR: 'ERROR',
	DEACTIVATED: 'DEACTIVATED',
	ACTIVATED: 'ACTIVATED',
	DECLINED: 'DECLINED',
	AWAITING_PAYMENT: 'AWAITING_PAYMENT',
	AWAITING_APPROVE: 'AWAITING_APPROVE',
	ARCHIVED_UNFINISHED: 'ARCHIVED_UNFINISHED',
	ARCHIVED_ERROR: 'ARCHIVED_ERROR'
}

export const ORDER_TYPE = {
	PAID: 'PAID',
	PAID_WITH_APPROVE: 'PAID_WITH_APPROVE',
	FREE: 'FREE',
	FREE_WITH_APPROVE: 'FREE_WITH_APPROVE'
}

export const DISCOUNT_TYPE = {
	FIXED: 'FIXED',
	PERCENT: 'PERCENT'
}

export const ACCESS_ROLES = {
	CO_ORGANIZER: 'CO_ORGANIZER',
	OPERATOR: 'OPERATOR',
	SALES: 'SALES',
	VOLUNTEER: 'VOLUNTEER'
}

export const CURRENCY_LIST = ['UAH', 'USD', 'EUR', 'PLN']

export const STATIC_PATHNAMES = [
	'/about',
	'/team',
	'/career',
	'/contact',
	'/features',
	'/terms',
	'/privacy',
	'/support',
	'/help',
	'/faq'
]

export const ACCEPTED_IMAGE_TYPES = [
	'image/jpeg',
	'image/png',
	'image/gif',
	'image/bmp',
	'image/webp',
	'image/svg+xml',
	'image/tiff'
]

export const ACCEPTED_FILE_TYPES = [
	'image/*',
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'text/plain',
	'text/csv',
	'application/rtf',
	'application/zip',
	'application/x-7z-compressed',
	'application/x-rar-compressed',
	'application/x-tar',
	'application/x-gzip',
	'application/x-bzip2',
	'application/x-xz',
	'application/x-rar-compressed',
	'application/x-7z-compressed',
	'application/x-tar',
	'application/x-gzip',
	'application/x-bzip2',
	'application/x-xz',
	'application/x-rar-compressed',
	'application/x-7z-compressed',
	'application/x-tar',
	'application/x-gzip',
	'application/x-bzip2',
	'application/x-xz'
]
