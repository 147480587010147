export default {
	financeDeleted: 'Фінансовий рахунок видалено',
	financeNotDeleted: 'Фінансовий рахунок не видалено',
	eventIsEmpty: 'Дана подія не існує, або не опубліковано організатором',
	ups: 'Уууупс ..... ',
	somethingWrong: 'щось пішло не так',
	integrationMinLength: 'Код занадто короткий. Мінімальне число символів повинно бути більше 5',
	inputFinance: 'Введіть дані фінансів',
	slugExists: 'Даний slug вже існує',
	enterSlug: 'Введіть slug',
	prevSlugNotAvailable: 'Зверніть увагу, що старе посилання на подію буде не доступна',
	'User with this Alias already exists': 'Користувач із цим псевдонімом уже існує.',

	'slug exists': 'Вибачте ви не можете зберегти даний slug він уже зайнятий',
	fillDateLocationName: 'Заповніть назву та адресу місця проведення',
	fillAllDateLocationName: 'Заповніть назву та адресу місця проведення для всіх мов',
	attemptsError: 'Кількість спроб авторизації перевищено. Повторити спробу через кілька секунд',
	'Please add phone number': 'Будь ласка, додайте номер телефону',
	'Please upload image': 'Будь ласка, завантажте зображення події',

	'Organizer with this Name already exists': 'Контактна особа з такою назвою вже існує',
	'Organizer with this Email already exists': 'Контактна особа з цим email вже існує',
	'Organizer with this Phone already exists': 'Контактна особа з цим номером телефону вже існує',
	incorrectSmsCode: 'Код невірний, або термін його дії закінчився',
	emailVerificateError:
		'Вибачте, але посилання підтвердження вже не активна або вже активована. Спробуйте ще раз.',
	ResetPasswordCodeError: 'Вибачте, але посилання вже не активна. \nСпробуйте ще раз.',
	'Field is required': "Обов'язкове поле",
	'Invalid credantails': 'Невірні облікові дані',
	'Invalid phone number': 'Неправильний номер телефону',
	'Enter a valid email address': 'Неправильний адрес електронної пошти',
	'Password must have at least 8 characters': 'Пароль повинен містити не менше 8 символів',
	'User with this Email already exists': 'Користувач з цією електронною поштою вже існує',
	'User with this Phone already exists': 'Користувач з цим номером телефону вже існує',
	'You are already subscribed': 'Ви вже підписані',
	'Incorrect old password': 'Неправильний старий пароль',
	'Incorrect coupon': 'Неправильний промокод',
	"End date can't be later than event's end date":
		'Дата закінчення не може бути пізніше дати закінчення івенту (%{date})',
	"Start date can't be after end date ": ' Дата початку не може бути пізніше дати закінчення ',
	'You can not set start date in the past': 'Ви не можете встановити дату початку минулого',
	'You can not set end date in the past': 'Ви не можете встановити дату закінчення в минулому',
	'You can not set end date before start': 'Ви не можете встановити дату закінчення до дати початку',
	'Something went wrong, contact support':
		'Щось пішло не так. Будь ласка, зверніться в службу підтримки. ',
	'Incorrect code': 'Невірний код. Проскануйте, будь ласка, QR-код ще раз ',
	'You have not access, contact organizer':
		"У вас немає прав для активації квитка. Будь ласка, зв'яжіться з організатором ",
	'Attendee status is invalid': 'Неможливо активувати квиток. Квиток має статус: ',
	'You can not added role to yourself': 'Ви не можете призначити роль на себе',
	'Invite already send': 'Запрошення вже відправлено',
	'Select available languages': 'Пожалуйста, выберите доступные языки',
	'Error occurred, check fields': 'Виникла помилка. Будь ласка, перевірте введені дані.',
	'No more tickets': 'Квитків більше немає',

	'Coupon is inactive': 'Прокомод не активний',
	'Please fill in all fields': 'Будь ласка, заповніть усі поля',
	'Please add days': 'Будь ласка, додайте день',
	'Please fill in all fields all lang': 'Будь ласка, заповніть всі поля для всіх мов',
	'Password contains invalid symbol': 'Пароль містить неправильні символи',
	'Please fill mandatory fields for main lang':
		'Будь ласка, заповніть обов’язкові поля для основної мови'
}
