import React, { PureComponent } from 'react'

import PI from 'react-phone-input-2'
import PropTypes from 'prop-types'

import s from './index.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(s)
/**
 * @description
 * Wrapper for https://github.com/bl00mber/react-phone-input-2
 */

export default class PhoneInput extends PureComponent {
	static propTypes = {
		classNames: PropTypes.string,
		className: PropTypes.string,
		error: PropTypes.bool,
		excludeCountries: PropTypes.arrayOf(PropTypes.string),
		onlyCountries: PropTypes.arrayOf(PropTypes.string),
		preferredCountries: PropTypes.arrayOf(PropTypes.string),
		defaultCountry: PropTypes.string,
		value: PropTypes.string,
		placeholder: PropTypes.string,
		containerClass: PropTypes.string,
		inputClass: PropTypes.string,
		buttonClass: PropTypes.string,
		dropdownClass: PropTypes.string,
		searchClass: PropTypes.string,
		containerStyle: PropTypes.object,
		inputStyle: PropTypes.object,
		buttonStyle: PropTypes.object,
		dropdownStyle: PropTypes.object,
		inputExtraProps: PropTypes.object,
		autoFormat: PropTypes.bool,
		disableAreaCodes: PropTypes.bool,
		disabled: PropTypes.bool,
		disableDropdown: PropTypes.bool,
		disableCountryCode: PropTypes.bool,
		enableLongNumbers: PropTypes.bool,
		countryCodeEditable: PropTypes.bool,
		enableSearchField: PropTypes.bool,
		onChange: PropTypes.func,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
		onKeyDown: PropTypes.func,
		onClick: PropTypes.func
	}

	static defaultProps = {
		disableAreaCodes: true,
		defaultCountry: 'ua',
		preferredCountries: ['ua', 'us']
	}
	render() {
		const excludeCountries = ['ru']

		return (
			<div className={`${cx(this.props.className)} ${s.Component}`}>
				<PI
					{...this.props}
					excludeCountries={excludeCountries}
					inputClass={`${s.ComponentInput} ${cx(this.props.classNames)}`}
				/>
			</div>
		)
	}
}
