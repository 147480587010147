import React, { memo, useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import IconAngle from 'components/Icons/Angle'
import { useTranslation } from 'react-i18next'

const cx = classNames.bind(styles)

type SelectedRange = {
	start: DateTime | null
	end: DateTime | null
}

export type DatePickerProps = {
	selectedDates: SelectedRange
	onSelect: (dates: SelectedRange) => void
}

type DayModel = {
	isNextMonth?: true
	isPrevMonth?: true
	date: DateTime
	isToday?: boolean
	disabled?: boolean
}

type DayProps = DayModel & {
	onSelect(d: DateTime): void
	selectedDates: SelectedRange
}

const Day = memo(function ({
	onSelect,
	isNextMonth,
	isPrevMonth,
	date,
	isToday,
	selectedDates,
	disabled
}: DayProps) {
	const start = Number(selectedDates.start)
	const end = Number(selectedDates.end)
	const dateInNumber = Number(date)
	const isRangeSeleced = start && end

	const isFirstActive = start === dateInNumber
	const isLastActive = end === dateInNumber
	const isActive = isRangeSeleced
		? start <= dateInNumber && dateInNumber <= end
		: isFirstActive || isLastActive

	return (
		<button
			type="button"
			disabled={disabled}
			onClick={() => onSelect(date)}
			className={cx('Day', {
				Day_today: isToday,
				Day_disabled: isNextMonth || isPrevMonth,
				Day_active: isActive,
				'Day_active-first': isFirstActive,
				'Day_active-last': isLastActive
			})}
		>
			{date.day}
		</button>
	)
})

/**
 * @important
 * Pass date and onSelect handler. If you will not do this - component will not work
 * It doesn't have self state
 */
function DatePicker({ onSelect, selectedDates }: DatePickerProps) {
	const [t, i18n] = useTranslation()
	const [calendar, setCalendar] = useState<DayModel[]>([])
	const [currentPage, setCurrentPage] = useState<DateTime>(DateTime.local())
	const NAME_DAYS: string[] = t('weekDays', { returnObjects: true })

	useEffect(updateCalendar, [currentPage])

	function onSelectDate(selectedDate: DateTime) {
		// nothing ckecked
		if (selectedDates.start === null) {
			onSelect({ ...selectedDates, start: selectedDate })
			// start was checked, set end of range
		} else if (selectedDates.end === null) {
			if (Number(selectedDate) < Number(selectedDates.start)) {
				onSelect({ start: selectedDate, end: selectedDates.start })
			} else {
				onSelect({ ...selectedDates, end: selectedDate })
			}
			// range setted, user want to change it to early, than now
			// or range setted, user want to change it to later, than now
		} else if (
			Number(selectedDates.start) > +selectedDate ||
			Number(selectedDates.end) < +selectedDate
		) {
			// set new start date, reset end date
			onSelect({ start: selectedDate, end: null })
			// range setted, user click on seleced date
		} else if (
			Number(selectedDates.start) === +selectedDate &&
			Number(selectedDates.end) === +selectedDate
		) {
			onSelect({ start: null, end: null })
		} else if (Number(selectedDates.start) === +selectedDate) {
			onSelect({ start: selectedDates.end, end: selectedDates.end })
		} else if (Number(selectedDates.end) === +selectedDate) {
			onSelect({ start: selectedDates.start, end: selectedDates.start })
		} else if (
			Number(selectedDates.start) < +selectedDate &&
			Number(selectedDates.end) > +selectedDate
		) {
			onSelect({ start: selectedDate, end: null })
		}
	}

	function updateCalendar() {
		const DAY_OF_WEEK = {
			MONDAY: 1,
			SUNDAY: 7
		}

		// DAYS IN CURRENT MONTH
		const today = +DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
		const firstDayInMOnth = DateTime.local(currentPage.year, currentPage.month, 1, 0, 0, 0, 0)
		const daysInCurrentMonth = firstDayInMOnth.daysInMonth
		const calendar: DayModel[] = []

		calendar.push({
			date: firstDayInMOnth,
			isToday: today === +firstDayInMOnth,
			disabled: today > +firstDayInMOnth
		})

		// DAYS IN CURRENT MONTH
		for (let i = 1; i < daysInCurrentMonth; i++) {
			const day = firstDayInMOnth.plus({ days: i })
			calendar.push({
				date: firstDayInMOnth.plus({ days: i }),
				isToday: today === +day,
				disabled: today > +day
			})
		}
		// DAYS IN PREVIOUS MONTH
		while (calendar[0].date.weekday !== DAY_OF_WEEK.MONDAY) {
			const date = calendar[0].date.minus({ day: 1 })
			calendar.unshift({
				isPrevMonth: true,
				disabled: today > +date,
				isToday: today === +date,
				date
			})
		}

		// // DAYS IN NEXT MONTH
		while (calendar[calendar.length - 1].date.weekday !== DAY_OF_WEEK.SUNDAY) {
			const date = calendar[calendar.length - 1].date.plus({ day: 1 })
			calendar.push({
				isNextMonth: true,
				disabled: today > +date,
				isToday: today === +date,
				date
			})
		}

		setCalendar(calendar)
	}

	function setMonth(v: 1 | -1) {
		setCurrentPage(DateTime.local(currentPage.year, currentPage.month).plus({ month: v }))
	}

	return (
		<div className={cx('Component')}>
			<div className={cx('Month')}>
				<button type="button" onClick={() => setMonth(-1)}>
					<IconAngle width={5} height={10} color="#565656" />
				</button>
				<span>{currentPage.setLocale(i18n.languages[0]).monthLong}</span>
				<button type="button" onClick={() => setMonth(1)}>
					<IconAngle width={5} height={10} color="#565656" />
				</button>
			</div>
			<div className={cx('Calendar')}>
				{NAME_DAYS.map((n, i) =>
					i > 4 ? (
						<b className={cx('Day')} key={n}>
							{n}
						</b>
					) : (
						<span className={cx('Day')} key={n}>
							{n}
						</span>
					)
				)}
				{calendar.map((day, i) => (
					<Day {...day} key={i} selectedDates={selectedDates} onSelect={onSelectDate} />
				))}
			</div>
		</div>
	)
}

export default memo(DatePicker)
