import React, { memo, useCallback, useState } from 'react'

import { Trans, withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ACCESS_ROLES } from 'constants/'

import { EyeIcon, PowerIcon } from 'pages/Event/Icons/Icons'

import generateNavs from './generateNavs'

import s from './HeaderSetting.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

const HeaderSetting = ({ changePublish, match, history }) => {
	const { isOwner, myRole, isPublished, slug: slugState } = useSelector(({ EventEdit }) => EventEdit)
	const slug = match && match.params && match.params.slug
	const url = history.location.pathname

	const path = `/event-edit/${slugState || slug}`
	const isFullAccess = isOwner || myRole === ACCESS_ROLES.CO_ORGANIZER
	const [t, setT] = useState(null)

	const onTabClick = useCallback(
		(e, tab) => {
			e && e.preventDefault()
			// history.block(true)
			history.push({ pathname: `${path}${tab ? '/' + tab : ''}` }, { some: slugState })
			setT(tab)
		},
		[t]
	)
	let navs = generateNavs(isOwner, myRole) || []
	return (
		<div className={cx('wrap-setting')}>
			<div className={'container'}>
				<div className={cx('items')}>
					<div className={cx('wrap-item')}>
						{navs.map(({ tab, trans, icon }, index) => {
							const isDesc = tab === 'description'
							return (
								<a
									key={index}
									onClick={(e) => onTabClick(e, isDesc ? '' : tab)}
									className={cx('nav', {
										active: isDesc ? url === path : url.includes(`/${tab}`)
									})}
								>
									<div className={cx('icon')}>{icon}</div>
									<span>
										<Trans i18nKey={`${trans}`} />
									</span>
								</a>
							)
						})}
						<Link
							className={cx('nav')}
							to={{
								pathname: `/event/${slug}?preview=true`
							}}
						>
							<div className={cx('icon')}>
								<EyeIcon fill={'var(--accent-color)'} />
							</div>
							<span>
								<Trans i18nKey="event.view" />
							</span>
						</Link>
					</div>
				</div>
				{isFullAccess && (
					<>
						<div className={cx('publish', { draft: isPublished })} onClick={changePublish}>
							<PowerIcon />
							<span className={cx('publish-text')}>
								<Trans i18nKey={`${isPublished ? 'event.draft' : 'event.publish'}`} />
							</span>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
export default withTranslation()(memo(HeaderSetting))
