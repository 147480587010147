export default {
	title: 'Результаты поиска',
	filters: 'Фильтры',
	apply: 'Применить',
	reset: 'Сбросить',
	emptyResult: 'Поиск не дал результатов',
	dateFilter: {
		label: 'Выберите дату/период:',
		range: {
			placeholder: 'Выберите период',
			value: 'от {{start}} - до {{end}}'
		},
		today: 'Сегодня',
		tomorrow: 'Завтра',
		thisWeek: 'На этой неделе'
	},
	priceFilter: {
		label: 'Выберите ценовую категорию',
		free: 'Бесплатные',
		inputPrice: 'Ввести цену',
		min: 'Мин.',
		price: 'Макс.'
	},
	sort: {
		label: 'Сортировка',
		NEW: 'Сначала самые новые',
		PRICE: 'Сначала самые дорогие',
		PRICE_DESC: 'Сначала самые дешевые',
		START: 'Сначала ближайшие',
		START_DESC: 'Сначала самые отдаленные'
	},
	objectInPage: {
		label: 'Показывать на странице:',
		value: '{{ value }} событий'
	},
	pagination: {
		next: 'Следующая',
		prev: 'Предыдущая',
		first: 'Первая',
		last: 'Последняя'
	}
}
