import React, { memo } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { connect } from 'react-redux'
import TicketBG from 'components/Icons/TicketBG'
import { useTranslation } from 'react-i18next'
import SearchInput from 'components/SearchEventInput'
import DateInput from './DateInput'
import { Dispatch } from 'redux'
import { ApplicationState } from 'store/reducers'
import { editSearch, editDateFilter } from 'store/Posters/actions'
import { DateRange } from 'store/Posters/reducer'
import { push } from 'connected-react-router'

const cx = classNames.bind(styles)

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

function Form({ search, date, onChangeSearch, editDate, openSearchPage }: Props) {
	const [t] = useTranslation()

	function onSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		openSearchPage()
	}

	return (
		<form className={cx('Filters')} onSubmit={onSubmit}>
			<SearchInput value={search} onChange={onChangeSearch} />
			<DateInput selectedDates={date} onSelect={editDate} />
			<div className={cx('FiltersSubmitWrap')}>
				<button type="submit" className={cx('FiltersSubmit')}>
					<TicketBG /> <span>{t('postersPage.search')}</span>
				</button>
			</div>
		</form>
	)
}

const mapState = ({
	Posters: {
		filters: {
			search,
			date: { range }
		}
	}
}: ApplicationState) => ({
	search,
	date: range
})

const mapDispatch = (dispatch: Dispatch) => ({
	onChangeSearch: (value: string) => {
		dispatch(editSearch(value))
	},
	editDate: (range: DateRange) => {
		dispatch(editDateFilter({ field: 'range', value: range }))
	},
	openSearchPage: () => {
		dispatch(push('/search'))
	}
})

export default connect(mapState, mapDispatch)(memo(Form))
