import React from 'react'

import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { getToken } from 'helpers/localStorage'

import ErrorPage from 'pages/Error'

export const Wrapper = ({ Component, needToken = false }) => {
	const WrappedComponent = (props) => {
		const { globalErrorStatus } = useSelector(({ Base }) => Base)
		const token = getToken()
		if (globalErrorStatus) {
			return <ErrorPage {...props} />
		} else if (needToken && !token) {
			return <Redirect to="/" />
		} else if (!needToken && token) {
			return <Redirect to="/dashboard" />
		}

		return <Component {...props} />
	}

	WrappedComponent.displayName = `Wrapped${Component.displayName || Component.name || 'Component'}`
	return WrappedComponent
}

Wrapper.displayName = 'Wrapper'
