//CONSTANTS

import { TABS } from 'constants'

const SET_MODIFY = 'SET_MODIFY'

const EDIT_FIELD = 'action/financesReducer/EDIT_FIELD'
const RESET = 'action/financesReducer/RESET'
const RESET_SCAN = 'action/financesReducer/RESET_SCAN'
const REMOVE_SCAN = 'action/financesReducer/REMOVE_SCAN'
const SAVE_SCAN = 'action/financesReducer/SAVE_SCAN'
export const SET_FINANCE_ID = 'action/financesReducer/SET_FINANCE_ID'

//ACTIONS
export const setModify = (id, merchantType) => ({ type: SET_MODIFY, id, merchantType })
export const editField = (field, value) => ({ type: EDIT_FIELD, field, value })
export const reset = (field, value) => ({ type: RESET, field, value })
export const removeScan = (name, scanType) => ({ type: REMOVE_SCAN, name, scanType })
export const resetScan = () => ({ type: RESET_SCAN })
export const saveScan = (scans) => ({ type: SAVE_SCAN, scans })

const initialState = {
	updateFinance: false,
	createFinance: false,
	financeId: '',
	financeMerchantType: '',
	selectedFinanceId: null,
	images: [],
	tab: TABS[0],
	paymentTab: 'paymentOnCard',
	scanType: 'PASSPORT',
	PASSPORT: [],
	INN: [],
	REGISTRY_REPORT: [],
	FONDY_CONTRACT: [],
	BANK_REPORT: [],
	COMPANY_STATUTE: [],
	DIRECTOR_PROTOCOL: [],
	FINANCE_REPORT: [],
	ORG_STRUCTURE: [],
	nameFinance: '',
	idScan: {
		image: ''
	},
	statement: {
		image: ''
	},
	sertificateFromBank: {
		image: ''
	},
	mfo: '',
	edrpou: '',
	companyName: '',
	bankAccountNumber: '',
	iban: ''
}
//REDUCER
export const financesReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_FINANCE_ID:
			return {
				...state,
				selectedFinanceId: action.financeId
			}
		case SET_MODIFY:
			return { ...state, financeId: action.id, financeMerchantType: action.merchantType }
		case EDIT_FIELD:
			return { ...state, [action.field]: action.value }
		case RESET:
			return initialState
		case REMOVE_SCAN:
			return {
				...state,
				[action.scanType]: []
			}
		case RESET_SCAN:
			return {
				...state,
				PASSPORT: [],
				INN: [],
				REGISTRY_REPORT: [],
				FONDY_CONTRACT: [],
				BANK_REPORT: [],
				COMPANY_STATUTE: [],
				DIRECTOR_PROTOCOL: [],
				FINANCE_REPORT: [],
				ORG_STRUCTURE: []
			}
		case SAVE_SCAN:
			return {
				...state,
				...action.scans
			}
		default:
			return state
	}
}
