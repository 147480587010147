export default {
	useDataProfile: 'Use data from your profile',
	addOwnLetter: 'Add your letter',
	nameOwnLetter: 'Letter title',
	locationOnMap: 'Location on the map:',
	needAddOrganizer: 'You need add an organizer',
	needActiveFinance: 'To publish, you need to have an active financial account',
	descriptionOwnLetter: 'Description of the letter',
	wantToAddDraft: 'This event has been published.',
	isDraftToAdd: 'Perhaps you want to hide it in drafts?',
	timeWarningMessage:
		'When setting the time, please note that 00-00 is the beginning of a new day, and 23-59 is the end of the day',

	yesDraft: 'Yes, hide',
	yesPublish: 'Yes, publish',
	wantToAddPublish:
		'This event has not been published and is only visible to those who have access to teeko.',
	isPublish: 'Perhaps you want to publish an event?',
	withoutScript: "Don't use tag script",
	addTicket: 'Add at least one ticket type to post',
	participants: {
		labels: {
			orders: 'Orders',
			addParticipant: 'Add participant',
			emailParticipant: 'Email participant',
			name: 'Name',
			firstCol: 'Name',
			email: 'Email',
			phone: 'Phone',
			ticketName: 'Ticket name',
			code: 'Code',
			ticketTypePrice: 'Price',
			createdFormat: 'Created',
			statusTrans: 'Status',
			paymentType: 'Payment type',
			coupon: 'Coupon'
		},
		filter: {
			all: 'All orders',
			allEndOrders: 'All completed orders',
			allNotEndOrders: 'All unfinished orders',
			orderWithPromo: 'Order using a promo code',
			orderAwait: 'Order pending confirmation'
		},
		searchPlaceholder: 'Order No., name, e-mail, phone number',
		filterDateFrom: 'Select period (from):',
		filterDateTo: 'Before:'
	},
	ticketsFieldsRequired:
		'To create a new question go back to the section "Creating tickets" and specify the data fields: ticket name, price, for all types of tickets. ',
	beginsMoreEnds: 'The start date of the event is later than the end date of the event.',
	beginsTimeMoreEnds: 'The start time of the event is later than the end time of the event.',

	PAYMENTS: {},
	promo: {
		addNewPromo: 'Adding a new promo code',
		addNewGroup: 'Adding a group discount\n',
		sumSale: 'Discount amount\n',
		name: 'Name',
		saleApply: 'Discount applies when purchasing',
		from: 'from',
		to: 'before',
		maxQuantityUnlimited: 'The maximum number of tickets for the discount is not limited\n',
		or: 'or',
		allTypes: 'All types',
		promoHasGroup: 'This promo code is a group one, the discount is applied when buying from',
		tickets: 'tickets',
		dateActivate: 'Activation date\n',
		dateDeactivate: 'Deactivation date\n',
		timeActivate: 'Activation time\n',
		timeDeactivate: 'Deactivation time\n',
		promoUnlimited: 'This promo code has no restrictions on use\n',
		thisPromoUse: 'This promo code can be used\n',
		time: 'time',
		nameDiscount: 'Name',
		activeFrom: 'Valid from',
		activeTo: 'Valid until',
		promoIsEmpty: 'The list of your promo codes is empty',
		labels: {
			promo: 'Promo code',
			discount: 'Discount',
			groupDiscount: 'Group discount',

			code: 'Promo code',
			prefix: 'Prefix',
			ticketTypes: 'Ticket Type',
			validTo: 'Valid to',
			type: 'Type',
			activations: 'Quantity',

			name: 'Name',
			begins: 'Valid from',
			ends: 'Valid to',
			validFrom: 'Valid'
		},
		validNone: 'Not specified',
		f: 'From',
		t: 'To'
	},
	fieldEmptyDay1: 'You have blank fields! Please click on',
	fieldEmptyDay2: ' and fill in all required fields (marked with an asterisk). ',
	fieldEmptyForMultilanguage:
		'Please note that you have multilingual events and required fields must be filled in for all languages',

	uploadDocument: 'Uploading documents\n',
	orDropFileHere: 'Or drag files here\n',
	downloadFile: 'Upload file\n',

	incorrectFormat: 'The image must be in the format: jpeg, jpg, pdf',
	downloadDocument: 'Upload documents',
	warningForQuestion:
		'You cannot save a new question without filling in all required fields (marked with an asterisk).\n' +
		'\n' +
		'Perhaps you forgot to specify the "Question" field for another language',
	youChoice: 'You chose the day',
	undoChange: 'Cancel change',
	saveChange: 'Save change',
	promoIncorrect: 'Promo incorrect',
	nameQuestion: 'Question title',
	actions: 'Act',
	events: 'Events',
	deleteContact: 'Delete contact',
	editContact: 'Edit contact',
	contactTitle: 'Contact persons',
	responseFor: 'Responsible for',
	selectContact: 'Select Contact',
	goodJob: 'Great job! You are almost done.',
	goodJobEditPage: 'Great job! Save Changes.',

	goodJobDescription:
		'Activate your event, manage and track your ticket sales with real-time reporting and analytics.',
	additionalPhone: 'Additional phone number',
	linkFacebook: 'Link to facebook',
	additionalContact: 'Additional Contact',
	addNewContact: 'Add contact',
	forSupport: 'for support',
	creatingEvent: 'Creating an event',
	editEvent: 'Event editing',
	creatingEventDescription:
		'Enter information about your event, fill in the appropriate fields and click the “save” button',
	commonInfo: 'General Information',
	addImageEvent: 'Add an event background image',
	uploadImage: 'Upload image',
	recommendEventImage: 'recommended size is 665 x 350, files must be in formats: jpeg, jpg, pdf',
	orDropHere: 'Or drag an image here',
	dateAndLocation: 'Date and location',
	oneLocation: 'One location for all days',
	eventOnline: 'Event Online',
	addDay: 'Add day',
	day: 'Day',
	selectDateStart: 'Event date (start)',
	selectTimeStart: 'Event time (start)',
	selectTimeEnd: 'Event time (end)',
	selectTimeZone: 'Select time zone',
	settingFinance: 'Setting up an account for withdrawing money',
	ddmmyy: 'dd/mm/yy',
	createTicket: 'Create tickets',
	nameTicket: 'Ticket name',
	quantityTicket: 'QUANTITY',
	priceTicket: 'Price',
	actionsTicket: 'Actions',
	ticketWithAgree:
		'Confirmed ticket (ticket can only be bought with confirmation from the organizer)',
	simplePay: 'Buy multiple tickets for one profile',
	ticketColor: 'Ticket color',
	hideSetting: 'Hide settings',
	selectStartProfit: 'Sales start date',
	selectEndProfit: 'End of sales date',
	selectTimeStartProfit: 'Sales start time',
	selectTimeEndProfit: 'Sales end time',
	registrationFormTitle: 'Registration Form',

	eventIsEmpty: 'Event list is empty',
	incorrectSymbol: 'Invalid symbol!',
	from: 'from ',
	add: 'Add',
	integrationModalTitle: 'Add',
	inputGA: 'Enter your Google Analytics code to receive event statistics:',
	inputFP: 'Enter your Facebook Pixel code to receive event statistics:',
	moveFile: 'Drag files here or',
	fileFormat: 'Files must be in the formats: jpeg, jpg, pdf',
	myLetter: 'I want my text for the letter',
	popup: {
		messageFirst: 'You have successfully signed up to event',
		messageSecond: 'Please check your mailbox',
		messageThree: ': incoming, promo, spam'
	},
	errorMessage: {
		ticketsAreBought: 'You cannot delete an event. PromoAndDiscount already purchased.',
		eventDelete: 'Event successfully deleted.',
		eventDontDelete: 'Event not deleted.'
	},
	registrationForm: {
		selectTypeQuestion: 'Choose the type of question',
		typeText: 'Text field',
		typeOptions: 'Answer options',
		typeFiles: 'File field',
		description:
			'Questions marked with an asterisk will be required to fill out the registration form',
		modal: {
			title: 'Adding a new question ',
			withVariant: 'with answer options',
			withFile: 'with file field',
			inputQuestion: 'Enter a question',
			selectTypeTickets: 'Select ticket type(s)',
			requiredQuestion: 'Question is required and will be marked',
			inputOwnAnswer: 'Enter your answer'
		}
	},

	subscribe: 'Subscribe to teeko newsletter',
	create: 'Create Event',
	duplicate: 'Duplicate',
	created: 'Event Created',
	generatingPayment: 'Expect, generate a form of payment',
	edited: 'Event changed',
	createEvent: 'Create event',
	isDraft: 'Draft',
	drafts: 'Drafts',
	draft: 'To Drafts',
	currency: 'Currency',
	published: 'Published',
	pastEvents: 'Past Events',
	eventsArchive: 'archived events',
	modify: 'Event cabinet',
	view: 'View',
	preview: 'Preview',
	publish: 'Publish',
	description: 'Description',
	shading: 'Add a blackout to the event image',
	addEventImg: 'Add event background image',
	eventImgRecommended: 'Recommended Size: 665x350',
	starts: 'Start of Event',
	ends: 'End of Event',
	startsSales: 'Start Sales',
	endsSales: 'End of Sales',
	title: 'Event Title',
	location: 'Location',
	locationTitle: 'Location Name',
	locationDescription: 'Location Description',
	dateAndTime: 'Date and time',
	addInCalendar: 'Add to calendar',
	shareEvent: 'Share the event with friends:',
	organizerName: 'Contact person',
	organizerSpecialization: 'On what subject to address',
	newOrganizer: 'New Contact',
	editOrganizer: 'Change Contact',

	addLogo: 'Add logo',
	tickets: 'Tickets',
	ticketsDelete: 'Ticket successfully deleted',
	isDeleteTicket: 'Delete ticket',
	organizerText:
		'You can add all other information about your organization after creation or you can',
	doItNow: 'do it now',
	users: 'users',
	ticketss: 'tickets',
	ticketsTitle: 'Promo and discounts',
	registrationTitle: 'Registration Form',
	participantsTitle: 'Participants',
	billingTitle: 'Billing information',
	integrationsTitle: 'Integration',
	statisticTitle: 'Statistics',
	accessTitle: 'Access rights',
	ticketsSold: 'Sold tickets',
	totalIncome: 'Total income online',
	phoneNumber: 'Phone number',
	totalAmount: 'Total amount:',
	agree: 'I agree ',
	agreeUse: 'to the terms of use ',
	agreeAnd: 'and ',
	agreePolicy: 'privacy policy',
	showOnMap: 'to show on the map',

	delete: 'Delete event',
	eventCart: {
		breadCrumb: {
			event: 'Events'
		},
		tabs: {
			all: 'All',
			active: 'Active',
			passed: 'Past',
			draft: 'Draft'
		},
		title: 'Event Management',
		contactPerson: 'Contact faces',
		contact: 'contact'
	},

	questions: {
		'First Name': 'Name',
		'Last Name': 'Last Name',
		'Phone Number': 'Phone Number',
		Email: 'Email'
	},
	attendees: {
		addForm: {
			title: 'Add member',
			selectTicketType: 'Select ticket type:',
			price: 'Paid amount'
		},
		showResult: 'View order',
		sendLetterAgain: 'Resend confirmation e-mail',
		sendAll: 'Send to all',
		send: 'send',
		activate: 'Activate',
		deactivate: 'Deactivate',
		refund: 'Return the money',
		activation: 'Ticket Activation',
		base: 'Base of participants',
		enterTextSms: 'Enter SMS Text',
		status: 'Status',
		price: 'Paid',
		ticketPrice: 'Ticket price',
		date: 'Date',
		questionnaire: 'Profile',
		statuses: {
			UNFINISHED: 'Payment Timeout',
			DECLINED: 'Confirmation rejected',
			PAID: 'Successfully Paid',
			REGISTERED: 'Registered',
			ERROR: 'Payment Error',
			DEACTIVATED: 'Deactivated',
			ACTIVATED: 'Activated',
			AWAITING_PAYMENT: 'Payment pending',
			AWAITING_APPROVE: 'Awaiting Confirmation',
			ARCHIVED_UNFINISHED: 'Archived'
		},
		filters: {
			ALL: 'All',
			WEEK: 'Last week',
			TODAY: 'Today,',
			PAID: 'Paid',
			DECLINED: 'Unpaid',
			APPROVE: 'Awaiting Confirmation'
		},
		actions: 'Actions',
		approve: 'Confirm',
		decline: 'Refuse'
	},
	ticket: {
		inputInfo: 'Please enter your guest information below\n',
		important:
			'Important: if you did not add tickets, the event is free and unlimited number of visitors',
		newType: 'New Ticket Type',
		free: 'free',
		name: 'Ticket name',
		date: 'Date',
		quantity: 'Quantity',
		ticket: 'Ticket',
		price: 'price',
		discount: 'Discount',
		discountIncluded: 'Congratulations, the group discount is included, it saved you',
		saved: 'Congratulations, you saved with the promo code',

		moreTicket: 'One more ticket',
		addCoupon: 'Add Promo Code',
		addDiscount: 'Add group discount',

		addDescription: 'Add Description',
		simpleCoupon: 'Ordinary promo code',
		multipleCoupon: 'Multiple promo codes',
		promocode: 'Promocode',
		remainingTickets: 'Tickets left:',
		total: 'Total:',
		end: 'Tickets sold out',
		salesEnd: 'Sale is over',
		signupTickets: 'Register tickets:',
		buy: 'Buy',
		pay: 'To pay',
		reg: 'Sign up',
		buyTicket: 'Buy tickets',
		buyTickets: 'Buy tickets:',
		advancedSettings: 'Advanced Settings',
		withApprove: 'Confirmed',
		simplifiedPurchase: 'Enable simplified purchase',
		uploadPicture: 'Upload image'
	},
	registration: {
		mainDescription: 'Description before registration form',
		addQuestion: 'Add question',
		editQuestion: 'Edit question',
		newQuestion: 'New Question',
		question: 'Question',
		type: 'Type',
		required: 'Required question',
		forSomeTypes: 'For tickets',
		edit: 'Edit',
		hint: 'Hint',
		create: 'Create',
		simpleText: 'Input line',
		bigText: 'Text Box',
		select: 'List',
		dropdown: 'Search List',
		options: 'Answer Options',
		addOption: 'Add option',
		questionPriority: 'Question sequence number'
	},
	integrations: {
		text: 'Here you can add your own scripts',
		ga: 'Enter your Google Analytics code to get statistics on the event',
		fp: 'Facebook Pixel'
	},
	access: {
		header: "Here you can manage access to other people's events.",
		items: [
			'- Co-organizer (has access to edit everything as a founder, but cannot delete the event).',
			'- Sales (has access to the list of participants of the event, can upload to xlsx, csv) and can change Google Analytics ID.',
			'- Volunteers (have access to the list of participants and activation of tickets at the entrance to the days of the event).'
		],
		add: 'Add person',
		role: 'role',
		roleType: {
			OPERATOR: 'Operator',
			CO_ORGANIZER: 'Co-Organizer',
			SALES: 'Sales Department',
			VOLUNTEER: 'Volunteer'
		},
		statusType: {
			ADDED: 'Added',
			INVITED: 'Invited'
		},
		name: 'Name',
		phone: 'Phone',
		status: 'Status',
		user: 'user',
		actions: 'Actions',
		invite: 'Invite',
		edit: 'Edit',
		remove: 'Remove',
		save: 'Save',
		cancel: 'Cancel'
	},
	billing: {
		createBilling: {
			text: 'Please fill your own first',
			link: 'financial information',
			orWait: 'or wait for confirmation created.'
		},
		chooseBilling: 'Please select and save an account',
		hideAll: 'Hide all',
		showAll: 'Show all'
	},
	payment: {
		PENDING: 'Your payment has been sent, please wait for confirmation.',
		OK: 'Congratulations, your payment is accepted!',
		FAILURE: 'Your payment is not accepted, please contact customer support.',
		downloadTickets: 'Download tickets',
		registratedSuccessful: 'Congratulations, registration completed successfully!',
		awaitingApprove:
			'Some of your tickets must be checked. Please wait for the letter to the email you specified '
	},
	creatingHint: 'Please create an event first',
	save: 'Save',
	organizerOf: 'Contact person',
	mainSettings: {
		title: 'Basic Settings'
	},
	locationSettings: {
		title: 'Location Settings'
	},
	languageSettings: {
		title: 'Language Settings',
		isMultilanguage: 'Multilingual Event',
		selectAvailable: 'Select available languages',
		selectDefault: 'Select Primary Language'
	},
	hint: {
		previous_text: 'Previously introduced: ',
		is_multilanguage_changed:
			'<0> Attention! </0> \nYou have changed the tinctures of the multilingual nature of your event, which is why your event was sent to drafts. \nPlease check that you have the necessary translations for events, tickets and questions in the registration form. \n Then publish event again.'
	}
}
