import React, { memo, useState, useRef, useEffect } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import IconCalendar from 'components/Icons/Calendar'
import DatePicker, { DatePickerProps } from 'components/DatePicker'
import { useTranslation } from 'react-i18next'
import InputWithButton from 'components/InputWithButton'
import IconClose from 'components/Icons/Close'
const cx = classNames.bind(styles)

function useVisible(): [boolean, () => void] {
	const [visible, setVisible] = useState(false)

	function toggle() {
		setVisible(!visible)
	}

	return [visible, toggle]
}

function DateInput(props: DatePickerProps) {
	const [visible, toggleVisible] = useVisible()
	const [t, i18n] = useTranslation()
	const ComponentRef = useRef<HTMLDivElement>(null)
	const dates = props.selectedDates
	const hasDates = dates.start || dates.end

	useEffect(() => {
		document.onmousedown = visible
			? (e: any) => {
					if (ComponentRef.current && !ComponentRef.current.contains(e.target)) {
						document.onmousedown = null
						toggleVisible()
					}
				}
			: null

		return () => {
			document.onmousedown = null
		}
	}, [visible])

	return (
		<div className={cx('Component', visible && 'Component_visible')} ref={ComponentRef}>
			<InputWithButton
				buttonIcon={<IconCalendar color="#fff" />}
				// onButtonClick={toggleVisible}
				readOnly={true}
				onDivClick={toggleVisible}
				placeholder={hasDates ? '' : t('searchPage.dateFilter.range.placeholder')}
				value={
					hasDates
						? (t('searchPage.dateFilter.range.value', {
								start: dates.start ? dates.start.setLocale(i18n.languages[0]).toFormat('dd MMMM') : '',
								end: dates.end ? dates.end.setLocale(i18n.languages[0]).toFormat('dd MMMM') : ''
							}) as string)
						: ''
				}
			/>

			{visible && (
				<div className={cx('Dropdown')}>
					<button onClick={toggleVisible} type="button" className={cx('DropdownClose')}>
						<IconClose />
					</button>
					<DatePicker {...props} />
				</div>
			)}
		</div>
	)
}

export default memo(DateInput)
