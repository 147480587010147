import React, { Fragment, memo, useCallback, useEffect, useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'

import IconBurger from 'components/Icons/Burger'
import Logo from 'components/Icons/Logo'

import { getToken, logOut } from '../../helpers/localStorage'

import LanguageSwitcher from 'pages/Landing/LanguageSwitcher'

import { showSignModal } from '../../store/Base/actions'

import Button from '../common/Button/Button'
import Preloader from '../Preloader'

import HeaderSetting from './HeaderSetting/HeaderSetting'
import NavBar from './Navbar/Navbar'

import s from './HeaderCustom.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

const HeaderCustom = ({
	links,
	classNames = '',
	setting,
	history,
	match,
	changePublish = () => {},
	redirect,
	onLanguageChange
}) => {
	const [open, setOpen] = useState(false)
	const [loader, setLoader] = useState(false)
	const [isScrolled, setIsScrolled] = useState(false)
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const isCreateEvent = match.path === '/create-event'
	const toggleNavBar = useCallback(() => {
		setOpen(!open)
	}, [open])
	const logOutCall = useCallback(async () => {
		setLoader(true)
		await logOut()
		setLoader(false)
	}, [])

	const handleCreateEvent = useCallback(() => {
		const token = getToken()
		if (token) {
			dispatch(push('/dashboard'))
		} else {
			dispatch(showSignModal({ tab: 'login' }))
		}
	}, [dispatch])

	const handleScroll = () => {
		setIsScrolled(window.scrollY > 65)
	}
	useEffect(() => {
		window.onscroll = handleScroll
		return () => {
			window.onscroll = null
		}
	}, [])
	if (loader) {
		return <Preloader />
	}
	return (
		<Fragment>
			<header
				className={cx('Component', classNames, {
					Shadow: isScrolled
				})}
			>
				<div className={cx('Container')}>
					<Link className={cx('Logo')} to="/">
						<Logo />
					</Link>
					<nav className={cx('Nav')}>{!!getToken() && links}</nav>
					<div className={cx('Actions')}>
						<LanguageSwitcher
							className={cx('LanguageSwitcher', 'LanguageSwitchCustom', {
								ml: !getToken() || !links
							})}
							isShort={false}
							onChange={onLanguageChange}
						/>
						<button type="button" onClick={toggleNavBar} className={cx('SidebarBtn')}>
							<IconBurger />
						</button>
					</div>
					<div className={cx('header-btns')}>
						{getToken() ? (
							<Button callback={logOutCall}>
								<Trans i18nKey="sign.out" />
							</Button>
						) : (
							<Button callback={handleCreateEvent}>{t('event.createEvent')}</Button>
						)}
					</div>
				</div>
				{setting && !isCreateEvent && (
					<HeaderSetting history={history} match={match} changePublish={changePublish} />
				)}
			</header>
			<NavBar
				redirect={redirect}
				links={links}
				callback={handleCreateEvent}
				getToken={getToken}
				onChange={onLanguageChange}
				isOpen={open}
				close={toggleNavBar}
			/>
		</Fragment>
	)
}

export default withRouter(memo(HeaderCustom))
