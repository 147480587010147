export default {
	title: 'Wyniki wyszukiwania',
	filters: 'Filtry',
	apply: 'Zastosuj',
	reset: 'Reset',
	emptyResult: 'Wyszukiwanie nie zwróciło wyników',
	dateFilter: {
		label: 'Wybierz datę / okres:',
		range: {
			placeholder: 'Wybierz okres',
			value: 'od {{start}} - do {{end}}'
		},
		today: 'Dzisiaj',
		tomorrow: 'Jutro',
		thisWeek: 'W tym tygodniu'
	},
	priceFilter: {
		label: 'Wybierz kategorię cenową',
		free: 'Wolny',
		inputPrice: 'Oferta',
		min: 'Min.',
		price: 'Max'
	},
	sort: {
		label: 'Sortuj',
		NEW: 'Nowy pierwszy',
		PRICE: 'Drodzy pierwsi',
		PRICE_DESC: 'Pierwsze tanie',
		START: 'Pierwsze przyjście',
		START_DESC: 'Pierwsza przyszłość'
	},
	objectInPage: {
		label: 'Pokaż na stronie:',
		value: 'Wydarzenia {{value}}'
	},
	pagination: {
		next: 'Dalej',
		prev: 'Poprzedni',
		first: 'Pierwszy',
		last: 'Ostatni'
	}
}
