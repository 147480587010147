export default {
	useDataProfile: 'Використовувати дані з профілю',
	addOwnLetter: 'Додати свій лист',
	nameOwnLetter: 'Назва листа',
	needAddOrganizer: 'Необхідно додати контактну особу',
	needActiveFinance: 'Для публікації необхідно мати активний фінансовий рахунок',

	descriptionOwnLetter: 'Опис листа',
	wantToAddDraft: 'Дана подія опублікована.',
	timeWarningMessage:
		'Встановлюючи час, зверніть увагу, що 00-00 - це початок нового дня, а 23-59 - закінчення дня',

	locationOnMap: 'Локація на карті:',
	isDraftToAdd: 'Можливо ви хочете приховати її в чернетки?',
	yesDraft: 'Так, приховати',
	yesPublish: 'Так, опублікувати',
	wantToAddPublish: 'Дана подія не опубліковано і видно тільки тим, кому відкритий доступ в teeko.',
	isPublish: 'Можливо ви хочете опублікувати подія?',
	withoutScript: 'Не використовуйте тег script',
	addTicket: 'Для публікації додайте, як мінімум, один тип квитка',

	participants: {
		labels: {
			orders: 'Замовлення',
			addParticipant: 'Додати учасника\n',
			emailParticipant: 'Email учасника',
			name: "Ім'я",
			firstCol: "Ім'я",
			email: 'Email',
			phone: 'Телефон',
			ticketName: 'Назва квитка',
			code: 'Код',
			ticketTypePrice: 'Ціна',
			createdFormat: 'Створений',
			statusTrans: 'Статус',
			paymentType: 'Тип оплати',
			coupon: 'Промокод'
		},
		filter: {
			all: 'Всі замовлення',
			allEndOrders: 'Всі завершенні замовлення',
			allNotEndOrders: 'Незавершені замовлення',
			orderWithPromo: 'Замовлення з використанням промокоду',
			orderAwait: 'Замовлення, які очікують підтвердження'
		},
		searchPlaceholder: "№ замовлення, ім'я, e-mail, номер телефону",
		filterDateFrom: 'Оберіть період (з):\n',
		filterDateTo: 'До:'
	},

	ticketsFieldsRequired:
		'Для створення нового питання поверніться до секції "Створення квитків" і вкажіть дані поля: назва квитка, ціна, для всіх типів квитка. ',

	beginsMoreEnds: 'Дата початку проведення події пізніше дати закінчення події.',
	beginsTimeMoreEnds: 'Час початку проведення події пізніше часу закінчення події.',

	promo: {
		addNewPromo: 'Додавання нового промокодом\n',
		addNewGroup: 'Додавання груповий знижки\n',
		sumSale: 'Сума знижки\n',
		name: 'Найменування',
		saleApply: 'Знижка застосовується при покупці\n',
		from: 'від',
		to: 'до',
		maxQuantityUnlimited: 'Максимальна кількість квитків для дії знижки, не обмежена\n',
		or: 'або',
		allTypes: 'Всі типи\n',
		promoHasGroup: 'Даний промокод є груповим, знижка застосовується при покупці від\n',
		tickets: 'квитків',
		dateActivate: 'Дата активації\n',
		dateDeactivate: 'Дата деактивації\n',
		timeActivate: 'Час активації\n',
		timeDeactivate: 'Час деактивації\n',
		promoUnlimited: 'Даний промокод не має обмежень у використанні\n',
		thisPromoUse: 'Даний промокод можна використовувати\n',
		time: 'раз',
		nameDiscount: 'Назва',
		activeFrom: 'Дійсний з  ',
		activeTo: 'Дійсний до\n',
		promoIsEmpty: 'Список ваших промокодом порожній',
		labels: {
			promo: 'Промокод',
			discount: 'Знижка',
			groupDiscount: 'Групова знижка',
			code: 'Промокод',
			prefix: 'Префікс',
			ticketTypes: 'Тип квитка',
			validTo: 'Дійсний до',
			type: 'Тип',
			activations: 'Кількість',
			name: 'Назва',

			begins: 'Дійсний з',
			ends: 'Дійсний до',
			validFrom: 'Дійсний'
		},
		validNone: 'Не вказано',
		f: 'З',
		t: 'До'
	},
	fieldEmptyDay1: 'У вас є не заповнені поля! Будь ласка, натисніть на',
	fieldEmptyDay2: " і заповніть всі обов'язкові поля (помічені зірочкою). ",
	fieldEmptyForMultilanguage:
		"Зверніть увагу що у вас мультимовна подія і обов'язкові поля повинні бути заповнені для всіх мов",

	uploadDocument: 'Завантаження документів',
	orDropFileHere: 'Або перетягніть файли сюди',
	downloadFile: 'Завантажити файл',

	incorrectFormat: 'Зображення повинно бути формату: jpeg, jpg, pdf',
	downloadDocument: 'Завантажити документи',
	warningForQuestion:
		"Ви не можете зберегти новий питання не заповнивши всі обов'язкові поля (помічені зірочкою).\n" +
		'\n' +
		'Можливо ви забули вказати поле "Питання" для іншої мови',
	youChoice: 'Ви вибрали день',
	undoChange: 'Відмінити зміни',
	saveChange: 'Зберегти зміни',
	promoIncorrect: 'Промокод невірний',
	nameQuestion: 'Найменування питання',
	actions: 'Дії',
	events: 'Події',
	deleteContact: 'Видалити контакт',
	editContact: 'Змінити контакт',
	contactTitle: 'Контактні особи',
	responseFor: 'Відповідає за',
	selectContact: 'Вибрати контакт',
	goodJob: 'Відмінна робота! Ви майже закінчили.',
	goodJobEditPage: 'Відмінна робота! Збережіть зміни.',
	goodJobDescription:
		'Активуйте свій івент, керуйте і відстежуйте свої продажі квитків за допомогою звітів і аналітики в реальному часі.',
	additionalPhone: 'Додатковий номер телефону',
	linkFacebook: 'Посилання на facebook',
	additionalContact: 'Додатковий контакт',
	addNewContact: 'Додати контакт',
	forSupport: 'для підтримки',

	creatingEvent: 'Створення заходу',
	editEvent: 'Редагування заходу',
	creatingEventDescription:
		'Вкажіть інформацію про ваш захід, заповніть відповідні поля і натисніть кнопку "зберегти"',
	commonInfo: 'Загальна інформация',
	addImageEvent: 'Додайте фонове зображення івенту',
	uploadImage: 'Завантажити зображення',
	recommendEventImage:
		'рекомендований розмір 665 x 350, файли повинні бути в форматах: jpeg, jpg, pdf',
	orDropHere: 'Чи перетягніть зображення сюди',
	dateAndLocation: 'Дата і локація',
	oneLocation: 'Oдна локація на всі дні',
	eventOnline: 'Захід Online',
	addDay: 'Додати день',
	day: 'День',
	selectDateStart: 'Дата проведення заходу (початок)',
	selectTimeStart: 'Час проведення заходу (початок)',
	selectTimeEnd: 'Час проведення заходу (закінчення)',
	selectTimeZone: 'Виберіть часовий пояс',
	settingFinance: 'Налаштування рахунку для виведення грошей',
	ddmmyy: 'дд/мм/рр',
	createTicket: 'Створення квитків',
	nameTicket: 'Назва квитка',
	quantityTicket: 'КІЛЬКІСТЬ',
	priceTicket: 'Ціна',
	actionsTicket: 'ДІЇ',
	ticketWithAgree:
		'Квиток з підтвердженням (квиток можна буде купити тільки з підтвердженням від організатора)',
	simplePay: 'Купити кілька квитків на одну анкету',
	ticketColor: 'Колір квитка',
	hideSetting: 'Приховати налаштування',
	selectStartProfit: 'Дата початку продажів',
	selectEndProfit: 'Дата закінчення продажів ',
	selectTimeStartProfit: 'Час початку продажів',
	selectTimeEndProfit: 'Тайм-аут закінчення продажів',
	registrationFormTitle: 'Форма реєстрації',

	eventIsEmpty: 'Список подій порожній',
	incorrectSymbol: 'Невалідний символ!',

	from: 'від ',
	add: 'Додати',
	integrationModalTitle: 'Додати',
	inputGA: 'Введіть ваш код Google Analytics, щоб отримувати статистику по івенту:',
	inputFP: 'Введіть ваш код Facebook Pixel, щоб отримувати статистику по івенту:',
	myLetter: 'Хочу свій текст для листа',
	moveFile: 'Перетягніть файли сюди чи',
	fileFormat: 'Файли повинні бути в форматах: jpeg, jpg, pdf',
	errorMessage: {
		ticketsAreBought: 'Ви не можете видалити подію. Квитки вже куплені.',
		eventDelete: 'Подія успішно видалено.',
		eventDontDelete: 'Подія не видалена.'
	},
	registrationForm: {
		selectTypeQuestion: 'Виберіть тип питання',
		typeText: 'Текстове поле',
		typeOptions: 'Варіанти відповідей',
		typeFiles: 'Поле з файлом',
		description: "Питання помічені зірочкою, будуть обов'язкові для заповнення в формі реєстрації",
		modal: {
			title: 'Додавання нового питання ',
			withVariant: 'з варіантами відповіді',
			withFile: 'з файловим полем',
			inputQuestion: 'Введіть питання',
			selectTypeTickets: 'Виберіть тип (и) квитка',
			requiredQuestion: "Питання є обов'язковим і буде позначений",
			inputOwnAnswer: 'Введіть свою відповідь'
		}
	},

	popup: {
		messageFirst: 'Ви успішно зареєструвались на подію',
		messageSecond: 'Перевірте свою поштову скриньку',
		messageThree: ': вхідні, промо, спам'
	},
	subscribe: 'Підписатися на розсилку від teeko',
	create: 'Створити подію',
	duplicate: 'Дублювати',
	created: 'Подія створена',
	generatingPayment: 'Чекайте, створюємо форму оплати',
	edited: 'Подія змінена',
	createEvent: 'Створити подію',
	isDraft: 'Чернетка',
	drafts: 'Чернетки',
	draft: 'В чернетки',
	currency: 'Валюта',
	published: 'Опубліковано',
	pastEvents: 'Минулі події',
	eventsArchive: 'Події в архіві',
	modify: 'Кабінет події',
	view: 'Перегляд',
	preview: 'Попередній',
	publish: 'Опублікувати',
	description: 'Опис',
	shading: 'Додати затемнення до зображення події',
	addEventImg: 'Додати фонове зображення події',
	eventImgRecommended: 'Рекомендований розмір: 665x350',
	starts: 'Початок події',
	ends: 'Закінчення події',
	startsSales: 'Початок продажу',
	endsSales: 'Закінчення продажу',
	title: 'Назва події',
	location: 'Місце проведення події',
	locationTitle: 'Назва місця проведення події',
	locationDescription: 'Опис місця проведення події',
	dateAndTime: 'Дата та час',
	addInCalendar: 'Додати в календар',
	shareEvent: 'Поділитися подією з друзями:',
	organizerName: 'Контактна особа',
	organizerSpecialization: 'З яких питань звертатись',
	newOrganizer: 'Нова контактна особа',
	editOrganizer: 'Змінити контактну особу',

	addLogo: 'Додати логотип',
	tickets: 'Квитки',
	ticketsDelete: 'Квиток успішно видалений',
	isDeleteTicket: 'Видалити квиток',
	organizerText:
		'Всю іншу інформацію про вашу організацію ви зможете додати після створення події або',
	doItNow: 'зробити це зараз',
	users: 'покупців',
	ticketss: 'квитків',
	ticketsTitle: 'Промокоди і скидки',
	registrationTitle: 'Форма реєстрації',
	participantsTitle: 'Покупці',
	billingTitle: 'Фінансова інформація',
	integrationsTitle: 'Інтеграції',
	statisticTitle: 'Статистика',
	accessTitle: 'Права доступу',
	ticketsSold: 'Квитків продано',
	totalIncome: 'Загальний прибуток онлайн',
	totalAmount: 'Загальна сума:',
	agree: 'Я погоджуюся з ',
	agreeUse: 'умовами використання ',
	agreeAnd: ' та ',
	agreePolicy: 'політикою конфіденційності',
	showOnMap: 'показати на карті',
	phoneNumber: 'Номер телефону',
	delete: 'Видалити подію',
	eventCart: {
		breadCrumb: {
			event: 'Події'
		},
		tabs: {
			all: 'Всі',
			active: 'Активні',
			passed: 'Минулі',
			draft: 'Чернетка'
		},
		title: 'Управління подіями',
		contactPerson: 'Контактні особи',
		contact: 'контакта'
	},
	questions: {
		'First Name': "Ім'я",
		'Last Name': 'Прізвище',
		'Phone Number': 'Номер телефону',
		Email: 'Email'
	},
	attendees: {
		addForm: {
			title: 'Додати учасника',
			selectTicketType: 'Виберіть тип квитка:',
			price: 'Оплачена сума'
		},
		showResult: 'Перегляд замовлення',
		sendLetterAgain: 'Переслати email з підтвердженням',
		sendAll: 'Відправити всім',
		send: 'Відправити',
		activate: 'Активувати',
		deactivate: 'Деактивувати',
		refund: 'Повернути гроші',
		activation: 'Активація квитків',
		base: 'База учасників',
		enterTextSms: 'Введіть текст SMS',
		status: 'Статус',
		price: 'Сплачено',
		ticketPrice: 'Ціна квитка',
		date: 'Дата',
		questionnaire: 'Анкета',
		phoneNumber: 'Номер телефону',

		statuses: {
			UNFINISHED: 'Незавершена оплата',
			DECLINED: 'Підтвердження відхилено',
			PAID: 'Оплачено',
			REGISTERED: 'Зареєстрований',
			ERROR: 'Помилка при оплаті (Fondy)',
			DEACTIVATED: 'Деактивований',
			ACTIVATED: 'Активований',
			AWAITING_PAYMENT: 'Очікується оплата',
			AWAITING_APPROVE: 'Очікується підтвердження',
			ARCHIVED_UNFINISHED: 'В архіві'
		},
		filters: {
			ALL: 'Всі',
			WEEK: 'За останній тиждень',
			TODAY: 'За сьогодні',
			PAID: 'Оплачені',
			DECLINED: 'Неоплачені',
			APPROVE: 'Очікують підтвердження'
		},
		actions: 'Дії',
		approve: 'Підтвердити',
		decline: 'Відмовити'
	},
	ticket: {
		inputInfo: 'Будь ласка, введіть інформацію про гостя нижче',
		important:
			'Ви можете створити різні типи квитків з різними цінами/ролями/датами продажу, зробити обмежену/необмежену кількість кожного типу квитка. \n\nТакож ви можете задати дату та час, коли розпочинається або закінчується продаж кожного типу квитка. Наприклад, створити квиток “рання пташка” та задати дату та час, коли можливість купівлі цього квитка автоматично зникне з сайту. І в той самий час автоматично з’явиться можливість купити інший тип квитка з вищою ціною, який був недоступний для купівлі раніше. \n\nВи маєте можливість створювати одноразові промокоди, промокоди на певну кількість учасників та промокоди на необмежену кількість використань. \n\nНазва промокода може бути задана вами, або автоматично згенерована сервісом. \n\nЗнижку у промокодах можно фіксувати як у гривнях так і у відсотках. Тобто ви можете створити промокод зі знижкою 10% або наприклад зі знижкою 100 гривень. Ви навіть можете створити промокод зі знижкою 100%, якщо це необхідно:)',
		newType: 'Новий тип квитків',
		free: 'Безкоштовно',
		name: 'Назва квитка',
		date: 'Дата',
		quantity: 'Кількість',
		ticket: 'Квиток',
		price: 'Ціна',
		discount: 'Знижка',
		discountIncluded: 'Вітаємо, включена групова знижка, це зберегло вам',
		saved: 'Вітаємо, із промокодом ви зекономили',
		moreTicket: 'Ще один квиток',
		addCoupon: 'Додати промокод',
		addDiscount: 'Додати групову знижку',

		addDescription: 'Додати опис',
		simpleCoupon: 'Звичайний промокод',
		multipleCoupon: 'Кілька промокодів',
		promocode: 'Промокод',
		remainingTickets: 'Залишилося квитків:',
		total: 'Всього:',
		end: 'Квитки закінчилися',
		salesEnd: 'Продаж закінчився',
		signupTickets: 'Придбати квитки:',
		buy: 'Купити',
		pay: 'Оплатити',
		reg: 'Зареєструватися',

		buyTicket: 'Купити квиток',
		buyTickets: 'Купити квитки:',
		advancedSettings: 'Додаткові налаштування',
		withApprove: 'З підтвердженням',
		simplifiedPurchase: 'Дозволити купівлю більше одного квитка на одну анкету',
		uploadPicture: 'Завантажити зображення'
	},
	registration: {
		mainDescription: 'Опис перед формою реєстрації',
		addQuestion: 'Додати питання',
		editQuestion: 'Змінити питання',
		newQuestion: 'Нове питання',
		question: 'Питання',
		type: 'Тип',
		required: "Обов'язкове питання",
		forSomeTypes: 'Для квитків',
		edit: 'Змінити',
		hint: 'Підказка',
		create: 'Створити',
		simpleText: 'Рядок введення',
		bigText: 'Поле для тексту',
		select: 'Список',
		dropdown: 'Список з пошуком',
		options: 'Варіанти відповідей',
		addOption: 'Додати варіант',
		questionPriority: 'Порядковий номер питання'
	},
	integrations: {
		text: 'Тут ви зможете додати власні скрипти',
		ga: 'Введіть ваш код Google Analytics, щоб отримувати статистику щодо заходу',
		fp: 'Facebook Pixel'
	},
	access: {
		header: 'Тут ви можете керувати доступом до роботи із подією для інших членів команди.',
		items: [
			'Ви можете самостійно додавати будь-яку кількість людей до адміністрування подією та керувати їх ролями:',
			'- Співорганізатор (має доступ редагувати все як засновник, але не може видалити захід).',
			'- Клієнт-менеджер(має доступ до списку учасників заходу, може вивантажувати в xlsx, csv) і може змінювати Google Analytics ID.',
			'- Волонтери (мають доступ до списку учасників і активації квитків на вході в дні заходу).',
			'- Оператор ( може створювати промокоди  та редагувати опис події)'
		],
		add: 'Додати людину',
		role: 'Роль',
		roleType: {
			OPERATOR: 'Оператор',
			CO_ORGANIZER: 'Співорганізатор',
			SALES: 'Клієнт-менеджер',
			VOLUNTEER: 'Волонтер'
		},
		statusType: {
			ADDED: 'Додано',
			INVITED: 'Запрошено'
		},
		name: "Ім'я",
		phone: 'Телефон',
		status: 'Статус',
		user: 'Користувач',
		actions: 'Дії',
		invite: 'Запросити',
		edit: 'Редагувати',
		remove: 'Видалити',
		save: 'Зберегти',
		cancel: 'Скасувати'
	},
	billing: {
		createBilling: {
			text: 'Будь ласка, додайте ',
			link: 'свої документи',
			orWait: ' для створення договору з teeko'
		},
		chooseBilling: 'Будь ласка, виберіть та збережіть рахунок',
		hideAll: 'Приховати всі',
		showAll: 'Показати всі'
	},
	payment: {
		PENDING: 'Ваш платіж відправлено, будь ласка зачекайте підтвердження.',
		OK: 'Вітаємо, ваш платіж прийнято!',
		FAILURE: "Ваш платіж не прийнято, будь ласка зв'яжіться зі службою підтримки.",
		downloadTickets: 'Завантажити квитки',
		registratedSuccessful: 'Вітаємо, реєстрація успішно завершена!',
		awaitingApprove:
			'Деякі з ваших квитків повинні пройти перевірку. Будь ласка, дочекайтеся листа на вказану вами пошту'
	},
	creatingHint: 'Будь ласка, спочатку створіть подію',
	save: 'Зберегти',
	organizerOf: 'Контактна особа',

	mainSettings: {
		title: 'Основні налаштування'
	},
	locationSettings: {
		title: 'Налаштування місця проведення події'
	},
	languageSettings: {
		title: 'Мовні налаштування',
		isMultilanguage: 'Багатомовна подія',
		selectAvailable: 'Оберіть доступні мови',
		selectDefault: 'Виберіть основну мову'
	},
	hint: {
		previous_text: 'Раніше було введено: ',
		is_multilanguage_changed:
			'<0> Увага! </0> \nВи змінили налаштування багатомовності вашої події, через що ваша подія була відправлена у чернетки. \nБудь ласка, перевірте наявність потрібних вам перекладів у ПОДІЇ, квитків і питань в формі реєстрації. \n Потім опублікуйте подію наново.'
	}
}
