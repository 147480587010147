import React, { memo } from 'react'

export const IconTelegram = memo(() => (
	<svg width="21" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M20.2 3.5l-3 14.1c-.2 1-.8 1.3-1.6.8L11 15l-2.2 2.2c-.3.2-.5.4-1 .4l.4-4.7 8.5-7.6c.3-.4-.1-.5-.6-.2L5.6 11.7l-4.5-1.4c-1-.3-1-1 .2-1.5L19 2c.8-.3 1.5.2 1.2 1.5z"
			fill="#575757"
		/>
	</svg>
))
