import React, { FC } from 'react'

import { Trans } from 'react-i18next'
import ReactTooltip from 'react-tooltip'

import s from './index.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

interface Props {
	locationName: string
	address: string
	locationDescription?: string
	id: string
}

export const AddressOffline: FC<Props> = ({ locationName, address, locationDescription, id }) => (
	<>
		<p style={{ cursor: 'pointer' }} data-for={id} data-tip="">
			{locationName && <h4>{locationName}</h4>}
			<p>{address}</p>
		</p>
		<ReactTooltip
			id={id}
			className={cx('hideIpad', 'Tooltip')}
			type="success"
			effect="solid"
			place="bottom"
		>
			<p style={{ color: '#fff' }}>{locationDescription}</p>
		</ReactTooltip>
		<div className={cx('showIpad')}>
			<div className={cx('titleIpad')}>
				<strong className={cx('fw600')}>
					<Trans i18nKey={'event.locationTitle'} /> :{' '}
				</strong>
				<p>{locationName}</p>
			</div>
			{locationDescription && (
				<div className={cx('locationDescription', 'titleIpad')}>
					<Trans i18nKey={'event.locationDescription'} />:<p>{locationDescription}</p>
				</div>
			)}
		</div>
	</>
)
