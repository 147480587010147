import { createAction } from 'helpers/createAction'
import ActionTypes from './actionTypes'
import { DateRange, PriceRange, ObjectsOnPage, ViewType } from './reducer'
import { PosterSortingEnum } from 'apollo/__generated_types__/globalTypes'

export const reset = () => createAction(ActionTypes.RESET)
export const resetDate = () => createAction(ActionTypes.RESET_DATE)

export const editSearch = (payload: string = '') => createAction(ActionTypes.EDIT_SEARCH, payload)

export type DateFilterPayload =
	| { field: 'range'; value: DateRange }
	| { field: 'today' | 'tomorrow' | 'thisWeek'; value: boolean }
export const editDateFilter = (payload: DateFilterPayload) =>
	createAction(ActionTypes.EDIT_DATE_FILTER, payload)

export type PriceFilterPayload =
	| { field: 'range'; value: PriceRange }
	| { field: 'free'; value: boolean }
export const editPriceFIlter = (payload: PriceFilterPayload) =>
	createAction(ActionTypes.EDIT_PRICE_FILTER, payload)

export const openPage = (payload: number) => createAction(ActionTypes.OPEN_PAGE, payload)

export const changeSort = (payload: PosterSortingEnum) =>
	createAction(ActionTypes.CHANGE_SORT, payload)

export const changeObjectsOnPage = (payload: ObjectsOnPage) =>
	createAction(ActionTypes.CHANGE_OBJECTS_ON_PAGE, payload)

export const switchView = (payload: ViewType) => createAction(ActionTypes.SWITCH_VIEW, payload)

export type Actions =
	| ReturnType<typeof editSearch>
	| ReturnType<typeof reset>
	| ReturnType<typeof resetDate>
	| ReturnType<typeof editDateFilter>
	| ReturnType<typeof editPriceFIlter>
	| ReturnType<typeof openPage>
	| ReturnType<typeof changeSort>
	| ReturnType<typeof changeObjectsOnPage>
	| ReturnType<typeof switchView>
