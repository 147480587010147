import React, { PureComponent } from 'react'

import { Trans, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { CSSTransition, Transition, TransitionGroup } from 'react-transition-group'
import { compose } from 'redux'

import { editAttendee, editTicket, onCreateOrder, setAttendees } from 'store/Event/actions'

import { AddIcon, GiftIcon } from '../Icons/Icons'

import AttendeeForm from './AttendeeForm'

import s from './../Event.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

class RegistrationForm extends PureComponent {
	state = {
		createOrderLoading: false,
		agreeWithTerms: false,
		agreeWithDataProcessing: false,
		error: '',
		showItem: true
	}

	whichShowMessage = (currentDiscount, currency) => {
		if (!currentDiscount.isGroupDiscount && currentDiscount.savings > 0) {
			return (
				<>
					<GiftIcon />
					<span>
						{' '}
						<Trans i18nKey={'event.ticket.saved'} /> {+currentDiscount.savings} {currency}
					</span>
				</>
			)
		} else if (currentDiscount.isGroupDiscount) {
			return (
				<>
					<GiftIcon />
					<span>
						<Trans i18nKey={'event.ticket.discountIncluded'} />
						{+currentDiscount.savings} {currency}
					</span>
				</>
			)
		}
	}
	addAttendeeForm = (ticketType, attendeesForms, addNewAttendee, ticketTypeId) => {
		const ticketQuantity = ticketType.availableQuantity || 100
		if (
			ticketQuantity
				? ticketQuantity > attendeesForms.length && !ticketType.simplePay
				: !ticketType.simplePay
		) {
			return (
				<div
					className={cx('add-item')}
					onClick={(e) => {
						e.preventDefault()
						addNewAttendee({
							id: ticketTypeId,
							value: +this.props.ticketTypes[ticketTypeId].selected + 1
						})
					}}
				>
					<AddIcon />
					<span>
						<Trans i18nKey={'event.ticket.moreTicket'} />
					</span>
				</div>
			)
		} else {
			return (
				<div className={cx('add-item')}>
					<span></span>
				</div>
			)
		}
	}

	render() {
		const {
			attendees,
			checkoutUrl,
			ticketTypes,
			addNewAttendee,
			paymentSubmitted,
			currentDiscount,
			lng
		} = this.props
		const attendeesKeys = Object.keys(attendees)
		const attendeesLength = attendeesKeys.reduce((sum, id) => (sum += attendees[id].length), 0)
		if (checkoutUrl || !attendeesLength || paymentSubmitted) return null

		return (
			<div className="event-registration-form">
				<TransitionGroup className="todo-list">
					{attendeesKeys
						.filter((ticketTypeId) => ticketTypeId == this.props.ticketType.oldId)
						.map((ticketTypeId) => {
							const attendeesForms = attendees[ticketTypeId]
							const ticketType = ticketTypes[ticketTypeId]
							if (!attendeesForms.length) return null
							return (
								<CSSTransition key={ticketTypeId} timeout={500}>
									<div className="ticket-form" id="ticket-form">
										<div className={cx('sale')}>
											<p className={cx('text-center')}>
												<Transition
													timeout={500}
													in={
														(!currentDiscount.isGroupDiscount && currentDiscount.savings > 0) ||
														currentDiscount.isGroupDiscount
													}
												>
													{(state) => (
														<div className={`fade ${state} `}>
															{this.whichShowMessage(this.props.currentDiscount, this.props.currency)}
														</div>
													)}
												</Transition>
											</p>
										</div>
										<TransitionGroup>
											{attendeesForms.map((form, i) => (
												<CSSTransition key={form.id} timeout={300} classNames="item">
													<AttendeeForm
														lng={lng?.toLowerCase()}
														getDiscountSum={this.props.getDiscountSum}
														getTotalSum={this.props.getTotalSum}
														id={this.props.id}
														ticketTypeId={ticketTypeId}
														attendee={form}
														isMultilanguage={this.props.isMultilanguage}
														defaultLanguage={this.props.defaultLanguage}
														attendeeIndex={i}
													/>
												</CSSTransition>
											))}
										</TransitionGroup>

										{this.addAttendeeForm(ticketType, attendeesForms, addNewAttendee, ticketTypeId)}
									</div>
								</CSSTransition>
							)
						})}
				</TransitionGroup>
			</div>
		)
	}
}

const mapState = ({ Event, router }) => ({
	attendees: Event.attendees,
	ticketTypes: Event.ticketTypes,
	checkoutUrl: Event.checkoutUrl,
	simplePay: Event.simplePay,
	paymentSubmitted: Event.paymentSubmitted,
	/* Parse query string */
	queryString: router.location.search
})

const mapDispatch = (dispatch) => ({
	resetAttedeesErrors(attendees) {
		dispatch(setAttendees(attendees))
	},
	edit(payload) {
		dispatch(editAttendee(payload))
	},

	addNewAttendee(payload) {
		dispatch(editTicket(payload))
		dispatch(setAttendees())
	},
	onCreateOrder(order) {
		dispatch(onCreateOrder(order))
	}
})

export default compose(connect(mapState, mapDispatch), withTranslation())(RegistrationForm)
