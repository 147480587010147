import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { HashLink } from 'components/HashLink'
import IconClose from 'components/Icons/Close'

import animatedScroll from 'helpers/animatedScroll'

import LanguageSwitcher from '../LanguageSwitcher/index'

import s from './index.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

function NavBar({ close, isOpen }) {
	let className = ''
	const [t] = useTranslation()
	/**
	 * Close navbar and scroll to need section
	 */

	const scroll = (el) => {
		animatedScroll(null, el)
		close()
	}

	return (
		<div className={`${cx('Container')} ${isOpen ? s.Open : ''} ${className}`}>
			<LanguageSwitcher className={cx('LanguageSwitcher')} isShort />
			<button className={cx('CloseBtn')} onClick={close}>
				<IconClose />
			</button>

			<nav className={cx('Nav')}>
				<HashLink scroll={scroll} to="/organizers#how-it-works">
					{t('landing.howItWorks.title')}
				</HashLink>
				<HashLink scroll={scroll} to="/organizers#pricing">
					{t('landing.pricing.title')}
				</HashLink>
				<HashLink scroll={scroll} to="/organizers#advantages">
					{t('landing.advantages.title')}
				</HashLink>
				<Link to="/terms">{t('footer.terms')}</Link>
				<Link to="/privacy">{t('footer.policy')}</Link>
				<Link to="/support">{t('footer.support')}</Link>
				<Link to="/faq">{t('footer.faq')}</Link>
			</nav>
		</div>
	)
}

export default memo(NavBar)
