export default {
	useDataProfile: 'Izmantojiet datus no sava profila',

	addOwnLetter: 'Pievienojiet savu vēstuli',
	nameOwnLetter: 'Vēstules nosaukums',
	needActiveFinance: 'Lai publicētu, jums ir jābūt aktīvam finanšu kontam',

	needAddOrganizer: 'Jāpievieno kontaktpersona',

	descriptionOwnLetter: 'Vēstules apraksts',
	wantToAddDraft: 'Šis pasākums ir publicēts.',
	isDraftToAdd: 'Varbūt vēlaties to paslēpt melnrakstos?',
	timeWarningMessage:
		'Iestatot laiku, lūdzu, ņemiet vērā, ka 00-00 ir jaunas dienas sākums, bet 23-59 ir dienas beigas',
	locationOnMap: 'Atrašanās vieta kartē:',

	yesDraft: 'Jā, paslēpies',
	yesPublish: 'Jā, publicē',
	wantToAddPublish: 'Šis pasākums nav publicēts un ir redzams tikai tiem, kam ir piekļuve teeko.',
	isPublish: 'Varbūt vēlaties publicēt kādu pasākumu?',
	withoutScript: 'Nelietojiet tagu script',
	addTicket: 'Izlikšanai pievienojiet vismaz vienu biļetes veidu',
	participants: {
		labels: {
			orders: 'Pasūtījumi',
			addParticipant: 'Pievienot dalībnieku',
			emailParticipant: 'Dalībnieka e-pasts',
			name: 'Nosaukums',
			firstCol: 'Nosaukums',
			email: 'Email',
			phone: 'Tālrunis',
			ticketName: 'Biļetes nosaukums',
			code: 'Kods',
			ticketTypePrice: 'Cena',
			createdFormat: 'Izveidots',
			statusTrans: 'Statuss',
			paymentType: 'Maksājuma veids',
			coupon: 'Kupons'
		},
		filter: {
			all: 'Visi pasūtījumi',
			allEndOrders: 'Visi izpildītie pasūtījumi',
			allNotEndOrders: 'Nepabeigtie pasūtījumi',
			orderWithPromo: 'Pasūtījumi, izmantojot reklāmas kodu',
			orderAwait: 'Pasūtījumi gaida apstiprinājumu'
		},
		searchPlaceholder: 'Pasūtījuma nr., nosaukums, e-pasts, tālruņa numurs',
		filterDateFrom: 'Atlasiet periodu (-us):\n',
		filterDateTo: 'Pirms:'
	},
	ticketsFieldsRequired:
		'Lai izveidotu jaunu jautājumu atgriezieties sadaļā "Biļešu izveide" un norādiet lauka datus: biļetes nosaukumu, cenu visiem biļešu veidiem. ',

	beginsMoreEnds: 'Pasākuma sākuma datums ir vēlāks par pasākuma beigu datumu',
	beginsTimeMoreEnds: 'Pasākuma sākuma laiks ir vēlāk nekā pasākuma beigu laiks.',

	promo: {
		addNewPromo: 'Jauna reklāmas koda pievienošana\n',
		addNewGroup: 'Pievienojot grupas atlaidi\n',
		sumSale: 'Atlaides summa\n',
		name: 'Nosaukums',
		saleApply: 'Pērkot, tiek piemērota atlaide\n',
		from: 'no',
		to: 'pirms',
		maxQuantityUnlimited: 'Maksimālais biļešu skaits uz atlaidi nav ierobežots\n',
		or: 'vai',
		allTypes: 'Visi veidi\n',
		promoHasGroup: 'Šis reklāmas kods ir grupas, atlaide tiek piemērota, pērkot no\n',
		tickets: 'biļetes',
		dateActivate: 'Aktivizācijas datums\n',
		dateDeactivate: 'Deaktivizācijas datums\n',
		timeActivate: 'Aktivizācijas laiks\n',
		timeDeactivate: 'Deaktivizācijas laiks\n',
		promoUnlimited: 'Šim reklāmas kodam nav lietošanas ierobežojumu\n',
		thisPromoUse: 'Šo reklāmas kodu var izmantot\n',
		time: 'laiks',
		nameDiscount: 'Nosaukums',
		activeFrom: 'Derīgs no\n',
		activeTo: 'Derīgs līdz\n',
		promoIsEmpty: 'Jūsu reklāmas kodu saraksts ir tukšs',
		labels: {
			promo: 'Reklāmas kods\n',
			discount: 'Atlaide\n',
			groupDiscount: 'grupa Atlaide\n',
			code: 'Kods',
			prefix: 'Prefikss',
			ticketTypes: 'Biļetes tips',
			validTo: 'Derīgs līdz',
			type: 'Tips',
			activations: 'Daudzums',

			name: 'Vārds',
			begins: 'Derīgs no',
			ends: 'Derīgs līdz',
			validFrom: 'Derīgs'
		},
		validNone: 'Nav precizēts',
		f: 'AR',
		t: 'Uz'
	},
	fieldEmptyDay1: 'Jums ir tukši lauki! Lūdzu, noklikšķiniet uz',
	fieldEmptyDay2: ' un aizpildiet visus obligātos laukus (atzīmēti ar zvaigznīti). ',
	fieldEmptyForMultilanguage:
		'Lūdzu, ņemiet vērā, ka jums ir daudzvalodu pasākumi un obligātie lauki ir jāaizpilda visās valodās',

	uploadDocument: 'Dokumentu augšupielāde\n',
	orDropFileHere: 'Vai velciet failus šeit\n',
	downloadFile: 'Augšupielādēt failu\n',
	incorrectFormat: 'Attēlam jābūt šādā formātā: jpeg, jpg, pdf',

	downloadDocument: 'Lejupielādējiet dokumentus',

	warningForQuestion:
		'Jūs nevarat saglabāt jaunu jautājumu, neaizpildot visus obligātos laukus (atzīmētus ar zvaigznīti).\n' +
		'\n' +
		'Varbūt esat aizmirsis norādīt lauku “Jautājums” citai valodai',
	youChoice: 'Jūs izvēlējāties dienu',
	undoChange: 'Atcelt izmaiņas',
	saveChange: 'Saglabāt izmaiņas',
	promoIncorrect: 'Promo nepareizi',
	nameQuestion: 'Jautājuma nosaukums',
	actions: 'Tēlot',
	events: 'Notikumi',
	deleteContact: 'Dzēst kontaktu',
	editContact: 'Rediģēt kontaktu',
	contactTitle: 'Kontaktpersonas',
	responseFor: 'Atbildīgs par',
	selectContact: 'Select Contact',
	goodJob: 'Lielisks darbs! Jūs esat gandrīz pabeidzis.',
	goodJobEditPage: 'Lielisks darbs! Saglabāt izmaiņas.',

	goodJobDescription:
		'Aktivizējiet notikumu, pārvaldiet un izsekojiet biļešu tirdzniecību, izmantojot reāllaika atskaites un analītiku.',
	additionalPhone: 'Papildu tālruņa numurs',
	linkFacebook: 'Saite uz facebook',
	additionalContact: 'Papildu kontakts',
	addNewContact: 'Pievienot kontaktu',
	forSupport: 'par atbalstu',
	creatingEvent: 'Notikuma izveidošana',
	editEvent: 'Notikumu rediģēšana',
	creatingEventDescription:
		"Ievadiet informāciju par savu notikumu, aizpildiet atbilstošos laukus un noklikšķiniet uz pogas' saglabāt ",
	commonInfo: 'Vispārīga informācija',
	addImageEvent: 'Pievienot notikuma fona attēlu',
	uploadImage: 'Augšupielādēt attēlu',
	recommendEventImage: 'ieteicamais izmērs ir 665 x 350, failiem jābūt formātos: jpeg, jpg, pdf',
	orDropHere: 'Vai arī velciet attēlu šeit',
	dateAndLocation: 'Datums un vieta',
	oneLocation: 'Viena vieta visām dienām',
	eventOnline: 'Event Online',
	addDay: 'Pievienot dienu',
	day: 'Diena',
	selectDateStart: 'Notikuma datums (sākums)',
	selectTimeStart: 'Notikuma laiks (sākums)',
	selectTimeEnd: 'Notikuma laiks (beigas)',
	selectTimeZone: 'Atlasīt laika joslu',
	settingFinance: 'Konta izveidošana naudas izņemšanai',
	ddmmyy: 'dd/mm/ggg',
	createTicket: 'Izveidot biļetes',
	nameTicket: 'Biļetes nosaukums',
	quantityTicket: 'QUANTITY',
	priceTicket: 'Cena',
	actionsTicket: 'ACTIONS',
	ticketWithAgree:
		'Apstiprināta biļete (biļeti var iegādāties tikai ar organizatora apstiprinājumu)',
	simplePay: 'Pirkt vairākas biļetes vienam profilam',
	ticketColor: 'Biļetes krāsa',
	hideSetting: 'Slēpt iestatījumus',
	selectStartProfit: 'Pārdošanas sākuma datums',
	selectEndProfit: 'Pārdošanas beigu datums',
	selectTimeStartProfit: 'Pārdošanas sākuma laiks',
	selectTimeEndProfit: 'Pārdošanas beigu laiks',
	registrationFormTitle: 'Reģistrācijas forma',

	eventIsEmpty: 'Notikumu saraksts ir tukšs',
	incorrectSymbol: 'Nederīgs simbols!',

	from: 'no plkst ',
	add: 'Pievienot',
	integrationModalTitle: 'Pievienojiet',
	inputGA: 'Ievadiet savu Google Analytics kodu, lai saņemtu notikumu statistiku:',
	inputFP: 'Ievadiet savu Facebook Pixel kodu, lai saņemtu notikumu statistiku:',
	moveFile: 'Velciet failus šeit vai',
	fileFormat: 'Failiem jābūt formātos: jpeg, jpg, pdf',
	myLetter: 'Es vēlos, lai mans teksts būtu vēstulei',
	popup: {
		messageFirst: 'Jūs esat veiksmīgi reģistrējies pasākumam.',
		messageSecond: 'Lūdzu, pārbaudiet savu pastu',
		messageThree: ': ienākošie, reklāmas, surogātpasts'
	},
	errorMessage: {
		ticketsAreBought: 'Jūs nevarat izdzēst notikumu. Biļetes jau nopirktas.',
		eventDelete: 'Pasākums veiksmīgi izdzēsts.',
		eventDontDelete: 'Notikums nav izdzēsts.'
	},
	registrationForm: {
		selectTypeQuestion: 'Izvēlieties jautājuma veidu',
		typeText: 'Teksta lauks',
		typeOptions: 'Atbilžu iespējas',
		typeFiles: 'Faila lauks',
		description: 'Jautājumi, kas apzīmēti ar zvaigznīti, būs jāaizpilda reģistrācijas veidlapā',
		modal: {
			title: 'Tiek pievienots jauns jautājums ',
			withVariant: 'ar atbilžu iespējām',
			withFile: 'ar faila lauku',
			inputQuestion: 'Ievadiet jautājumu',
			selectTypeTickets: 'Biļetes veida (-u) izvēle',
			requiredQuestion: 'Jautājums ir nepieciešams un tiks atzīmēts',
			inputOwnAnswer: 'Ievadiet atbildi'
		}
	},

	subscribe: 'Abonējiet teeko biļetenu',
	duplicate: 'Dublikāts',
	create: 'Izveidot notikumu',
	created: 'Notikums ir izveidots',
	generatingPayment: 'Gaidīt, ģenerējiet maksājuma veidu',
	edited: 'Notikums mainīts',
	createEvent: 'Izveidot notikumu',
	isDraft: 'Melnraksts',
	drafts: 'Melnraksti',
	draft: 'Melnrakstos',
	currency: 'Valūta',
	published: 'Publicēts',
	pastEvents: 'Iepriekšējie notikumi',
	eventsArchive: 'arhivētie notikumi',
	modify: 'Pasākumu skapis',
	view: 'Skatīt',
	preview: 'Priekšskatījums',
	publish: 'Publicēt',
	description: 'Apraksts',
	shading: 'Pievienojiet izslēgšanu notikuma attēlā',
	addEventImg: 'Pievienot notikuma fona attēlu',
	eventImgRecommended: 'Ieteicamais izmērs: 665x350',
	starts: 'Notikuma sākums',
	ends: 'Notikuma beigas',
	startsSales: 'Pārdošanas sākums',
	endsSales: 'Pārdošanas beigas',
	title: 'Notikuma nosaukums',
	location: 'Atrašanās vieta',
	locationTitle: 'Atrašanās vietas nosaukums',
	locationDescription: 'Atrašanās vietas apraksts',
	dateAndTime: 'Datums un laiks',
	addInCalendar: 'Pievienot kalendāram',
	shareEvent: 'Dalieties pasākumā ar draugiem:',
	organizerName: 'Kontaktpersona',
	organizerSpecialization: 'Kāds jautājums jārisina',
	newOrganizer: 'Jauns kontakts',
	editOrganizer: 'Mainīt kontaktpersonu',

	addLogo: 'Pievienot logotipu',
	tickets: 'Biļetes',
	ticketsDelete: 'Biļete ir veiksmīgi izdzēsta',
	isDeleteTicket: 'Vai izdzēst biļeti',
	organizerText:
		'Jūs varat pievienot visu citu informāciju par jūsu organizāciju pēc izveidošanas vai arī jūs varat',
	doItNow: 'darīt to tagad',
	users: 'lietotāji',
	ticketss: 'biļetes',
	ticketsTitle: 'Reklāmas kodi un atlaides',
	registrationTitle: 'Reģistrācijas forma',
	participantsTitle: 'Biedri',
	billingTitle: 'Norēķinu informācija',
	integrationsTitle: 'Integrācija',
	statisticTitle: 'Statistika',
	accessTitle: 'Piekļuves tiesības',
	ticketsSold: 'Biļetes izpārdotas',
	totalIncome: 'Kopējie ienākumi online',
	phoneNumber: 'Tālruņa numurs',
	agree: 'Es piekrītu ',
	agreeUse: 'lietošanas noteikumiem ',
	agreeAnd: 'un ',
	agreePolicy: 'privātuma politikai',
	showOnMap: 'lai parādītu kartē',
	totalAmount: 'Kopējā summa:',

	delete: 'Dzēst notikumu',
	eventCart: {
		breadCrumb: {
			event: 'Notikumi'
		},
		tabs: {
			all: 'Visi',
			active: 'Aktīvs',
			passed: 'Iepriekšējie',
			draft: 'Melnraksts'
		},
		title: 'Pasākumu vadība',
		contactPerson: 'Kontaktu sejas',
		contact: 'kontakts'
	},

	questions: {
		'First Name': 'Vārds',
		'Last Name': 'Uzvārds',
		'Phone Number': 'Tālruņa numurs',
		Email: 'Email'
	},
	attendees: {
		addForm: {
			title: 'Pievienot dalībnieku',
			selectTicketType: 'Izvēlieties biļetes veidu:',
			price: 'Apmaksātā summa'
		},
		showResult: 'Skatīt pasūtījumu',
		sendLetterAgain: 'Pārsūtīt apstiprinājuma e-pastu',
		sendAll: 'Sūtīt visiem',
		send: 'Iesniegt',
		activate: 'Aktivizēt',
		deactivate: 'Deaktivizēt',
		refund: 'Atgriezties naudu',
		activation: 'Biļešu aktivizēšana',
		base: 'Dalībnieku bāze',
		enterTextSms: 'Ievadiet SMS īsziņu',
		status: 'Statuss',
		price: 'Apmaksātais',
		ticketPrice: 'Biļešu cena',
		date: 'Datums',
		questionnaire: 'Anketa',
		statuses: {
			UNFINISHED: 'Maksājuma taimauts',
			DECLINED: 'Apstiprinājums noraidīts',
			PAID: 'Veiksmīgi samaksāts',
			REGISTERED: 'Reģistrēts',
			ERROR: 'Maksājuma kļūda',
			DEACTIVATED: 'Deaktivizēts',
			ACTIVATED: 'Aktivizēts',
			AWAITING_PAYMENT: 'Maksājums vēl nav pabeigts',
			AWAITING_APPROVE: 'Gaida apstiprinājumu',
			ARCHIVED_UNFINISHED: 'Arhivēts'
		},
		filters: {
			ALL: 'Visi',
			WEEK: 'Pagājušajā nedēļā',
			TODAY: 'Par šodien',
			PAID: 'Apmaksātais',
			DECLINED: 'Neapmaksāta',
			APPROVE: 'Gaida apstiprinājumu'
		},
		actions: 'Darbības',
		approve: 'Apstiprināt',
		decline: 'Atteikt'
	},
	ticket: {
		inputInfo: 'Lūdzu, zemāk ievadiet informāciju par viesiem',
		important:
			'Svarīgi: ja jūs nepievienojat biļetes, pasākums ir bezmaksas un neierobežots apmeklētāju skaits',
		newType: 'Jauns biļetes veids',
		free: 'Bezmaksas',
		name: 'Biļetes nosaukums',
		date: 'Datums',
		quantity: 'Daudzums',
		ticket: 'Biļete',
		price: 'Cena',
		discount: 'Atlaide',
		discountIncluded: 'Apsveicam, grupas atlaide ir iekļauta, tas jūs ietaupīja',
		saved: 'Apsveicam! Jūs saglabājāt reklāmas kodu',
		moreTicket: 'Vēl viena biļete',
		addCoupon: 'Pievienot reklāmas kodu',
		addDiscount: 'Pievienot grupas atlaidi',
		addDescription: 'Pievienot aprakstu',
		simpleCoupon: 'Parastais reklāmas kods',
		multipleCoupon: 'Vairāki reklāmas kodi',
		promocode: 'Promokode',
		remainingTickets: 'Biļetes pa kreisi:',
		total: 'Kopā:',
		end: 'Biļetes ir beigusies',
		salesEnd: 'Pārdošana ir beigusies',
		signupTickets: 'Reģistrēt biļetes:',
		buy: 'Pirkt',
		pay: 'Lai maksā',
		reg: 'Reģistrējieties',
		buyTicket: 'Pirkt biļetes',
		buyTickets: 'Pirkt biļetes:',
		advancedSettings: 'Papildu iestatījumi',
		withApprove: 'Ar apstiprinājumu',
		simplifiedPurchase: 'Iespējot vienkāršotu pirkumu',
		uploadPicture: 'Augšupielādēt attēlu'
	},
	registration: {
		mainDescription: 'Apraksts pirms reģistrācijas veidlapas',
		addQuestion: 'Pievienot jautājumu',
		editQuestion: 'Mainīt jautājumu',
		newQuestion: 'Jauns jautājums',
		question: 'Jautājums',
		type: 'Tips',
		required: 'Nepieciešamais jautājums',
		forSomeTypes: 'Par biļetēm',
		edit: 'Mainīt',
		hint: 'Padoms',
		create: 'Izveidot',
		simpleText: 'Ievades līnija',
		bigText: 'Teksta lodziņš',
		select: 'Saraksts',
		dropdown: 'Meklēšanas saraksts',
		options: 'Atbildes opcijas',
		addOption: 'Pievienot opciju',
		questionPriority: 'Jautājuma secības numurs'
	},
	integrations: {
		text: 'Šeit varat pievienot savus skriptus',
		ga: 'Lai iegūtu notikumu statistiku, ievadiet savu Google Analytics kodu',
		fp: 'Facebook pikseļi'
	},
	access: {
		header: 'Šeit jūs varat kontrolēt piekļuvi citu cilvēku notikumiem.',
		items: [
			'- līdzorganizators (ir iespēja rediģēt visu kā dibinātāju, bet nevar izdzēst notikumu).',
			'- Pārdošana (var piekļūt notikuma dalībnieku sarakstam, var augšupielādēt uz xlsx, csv) un var mainīt Google Analytics ID.',
			'- Brīvprātīgie (piekļūt dalībnieku sarakstam un biļešu aktivizēšanai pie ieejas līdz notikuma dienām).'
		],
		add: 'Pievienot personu',
		role: 'Loma',
		roleType: {
			OPERATOR: 'Operators',
			CO_ORGANIZER: 'Līdzorganizators',
			SALES: 'Tirdzniecības departaments',
			VOLUNTEER: 'Brīvprātīgais'
		},
		statusType: {
			ADDED: 'Pievienots',
			INVITED: 'Uzaicināts'
		},
		name: 'Vārds',
		phone: 'Telefons',
		status: 'Statuss',
		user: 'Lietotājs',
		actions: 'Darbības',
		invite: 'Uzaicināt',
		edit: 'Rediģēt',
		remove: 'Dzēst',
		save: 'Saglabāt',
		cancel: 'Atcelt'
	},
	billing: {
		createBilling: {
			text: 'Lūdzu, vispirms aizpildiet savu',
			link: 'finanšu informācija',
			orWait: 'vai gaidiet apstiprinājuma izveidi.'
		},
		chooseBilling: 'Lūdzu, atlasiet un saglabājiet kontu',
		hideAll: 'Paslēpt visus',
		showAll: 'Rādīt visu'
	},
	payment: {
		PENDING: 'Jūsu maksājums ir nosūtīts, lūdzu, uzgaidiet apstiprinājumu.',
		OK: 'Apsveicam, jūsu maksājums ir pieņemts!',
		FAILURE: 'Jūsu maksājums netiek pieņemts, lūdzu, sazinieties ar atbalsta dienestu.',
		downloadTickets: 'Lejupielādēt biļetes',
		registratedSuccessful: 'Apsveicam, reģistrācija ir veiksmīgi pabeigta!',
		awaitingApprove:
			'Dažas no jūsu biļetēm ir jāpārbauda. Lūdzu, uzgaidiet, līdz vēstule tiks norādīta uz jūsu norādīto e-pasta adresi, '
	},
	creatingHint: 'Lūdzu, vispirms izveidojiet notikumu',
	save: 'Saglabāt',
	organizerOf: 'Kontaktpersona',

	mainSettings: {
		title: 'Pamata iestatījumi'
	},
	locationSettings: {
		title: 'Atrašanās vietas iestatījumi'
	},
	languageSettings: {
		title: 'Valodas iestatījumi',
		isMultilanguage: 'Daudzvalodu pasākums',
		selectAvailable: 'Atlasīt pieejamās valodas',
		selectDefault: 'Izvēlieties savu galveno valodu'
	},
	hint: {
		previous_text: 'Iepriekš tas tika ieviests: ',
		is_multilanguage_changed:
			'<0>. Piezīme:. </0> \nJūs mainījies tinktūra daudzvalodu Jūsu pasākumu, jo to, ko jūsu pasākums ir nosūtīts projektā \n Lūdzu pārbaudiet vēlamo tulkojumu sobitiya, biļetes un jautājumiem reģistrācijas veidlapā \n tam publicēt notikums vēlreiz.'
	}
}
