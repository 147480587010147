export default {
	title: 'Paieškos rezultatai',
	filters: 'Filtrai',
	apply: 'Taikyti',
	emptyResult: 'Paieška nedavė jokių rezultatų',
	reset: 'Atstatyti',
	dateFilter: {
		label: 'Pasirinkite datą / laikotarpį:',
		range: {
			placeholder: 'Pasirinkite laikotarpį',
			value: 'nuo {{start}} - iki {{end}}'
		},
		today: 'Šiandien',
		tomorrow: 'Rytoj',
		thisWeek: 'Šią savaitę'
	},
	priceFilter: {
		label: 'Pasirinkite kainų kategoriją',
		free: 'nemokamai',
		inputPrice: 'Įveskite kainą',
		min: 'Min.',
		price: 'Max.'
	},
	sort: {
		label: 'Rūšiuoti',
		NEW: 'Naujas pirmasis',
		PRICE: 'Pirmasis brangus',
		PRICE_DESC: 'Pirmiausia pigiai',
		START: 'Pirmasis atėjimas',
		START_DESC: 'Pirmiausia ateitis'
	},
	objectInPage: {
		label: 'Rodyti puslapyje:',
		value: '{{value}} įvykiai'
	},
	pagination: {
		next: 'Kitas',
		prev: 'Ankstesnis',
		first: 'Pirmasis',
		last: 'Paskutinis'
	}
}
