import React, { PureComponent } from 'react'

export default class IconBurger extends PureComponent {
	render() {
		return (
			<svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect y="0.227051" width="17.4224" height="1.5" rx="0.75" fill="#575757" />
				<rect x="0.000488281" y="8.63794" width="17.4224" height="1.5" rx="0.75" fill="#575757" />
				<rect x="2.00049" y="4.43237" width="12.0154" height="1.5" rx="0.75" fill="#575757" />
			</svg>
		)
	}
}
