import { gql } from '@apollo/client'

export default gql`
	mutation UpdateEvent($event: EventInput!) {
		updateEvent(event: $event) {
			ok
			event {
				id
				name
				slug
			}
		}
	}
`
