export default {
	long: {
		en: 'Англійська',
		uk: 'Українська',
		he: 'Іврит',
		pl: 'Польська',
		lv: 'Латвійська',
		lt: 'Литовська',
		ru: 'Російська'
	},
	short: {
		en: 'Анг',
		uk: 'Укр',
		he: 'Івр',
		pl: 'Пол',
		lv: 'Лат',
		lt: 'Лит',
		ru: 'Рос'
	},
	en: 'Англійська',
	uk: 'Українська',
	he: 'Іврит',
	pl: 'Польська',
	lv: 'Латвійська',
	lt: 'Литовська',
	ru: 'Російська'
}
