export default {
	organizerLanding: 'Як це працює',
	company: 'Компанія',
	aboutUs: 'Про нас',
	team: 'Команда',
	career: "Кар'єра",
	contacts: 'Контакти',
	resources: 'Організаторам',
	terms: 'Умови користування',
	policy: 'Політика конфіденційності',
	support: 'База знань',
	faq: 'FAQ',
	telegram: "Зв'язатися з нами в telegram",

	office: {
		title: 'Контакти',
		street: 'вул. Шауль Харнам 6, 38 ',
		city: 'Петах-Тіква, Ізраїль, 4977396',
		address: 'Петах-Тіква, Шауль Харнам 6, 38'
	},

	madeBy: 'made by'
}
