import React from 'react'

import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ACCESS_ROLES } from 'constants'

import s from './Dashboard.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

const SettingEvent = ({
	setShow,
	show,
	myRole,
	slug,
	isOwner,
	loadingDuplicate,
	duplicateEvent,
	id,
	setShowAgree,
	setIdDelete
}) => {
	return (
		<>
			<div onMouseLeave={() => setShow(false)} className={cx('setting-event', { show: show })}>
				{myRole !== ACCESS_ROLES.VOLUNTEER && (
					<Link to={`/event-edit/${slug}`}>
						<Trans i18nKey="event.modify" />
					</Link>
				)}
				<Link to={`/event/${slug}?preview=true`}>
					<Trans i18nKey="event.view" />
				</Link>
				{myRole !== ACCESS_ROLES.OPERATOR && (
					<Link to={`/event-edit/${slug}/participants`}>
						<Trans i18nKey="event.attendees.base" />
					</Link>
				)}
				{(isOwner || myRole === ACCESS_ROLES.CO_ORGANIZER) && (
					<>
						<button type="button" disabled={loadingDuplicate} onClick={() => duplicateEvent()}>
							<Trans i18nKey="event.duplicate" />
						</button>
						{isOwner && (
							<button
								onClick={() => {
									setIdDelete(id)
									setShowAgree(true)
								}}
							>
								<Trans i18nKey={'event.delete'} />
							</button>
						)}
					</>
				)}
			</div>
		</>
	)
}
export default SettingEvent
