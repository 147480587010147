export default {
	Enter: 'Введіть',

	profile: 'Мій кабінет',
	account: 'Мій аккаунт',
	general: 'Загальні',
	finances: 'Фінанси',
	infoFull: 'Ваш аккаунт заповнений повністю',
	infoNotFull: 'Ваш аккаунт заповнений не повністю',
	editData: 'Редагувати дані',
	cashWithdrawal: 'Виведення коштів',
	withdrawMoney: 'Вивести кошти',
	uploadImageDescription:
		'Завантажте скан-копії документів (паспорт, ідентифікаційний номер, виписки з єдиного реєстру, довідка з банку про відкриття рахунку)',
	uploadImageDescriptionNatural:
		'Завантажте скан-копії документів (паспорт, ідентифікаційний номер)',
	uploadImageFormat: 'Файлы повинні бути в форматах: jpeg, jpg,pdf',
	uploadImageDetail1:
		'скани паспорта з 1 по 11 сторінку, включно зі сторінками реєстрації і наступну порожню (щоб фінансовому процесингу було видно, що прописка в сканах варто найостанніша)',
	uploadImageDetail2: 'скани повинні бути в розвороті (по 2 сторінки на скан)',
	uploadImageDetail3: 'скан внутрішньої ID картки, яка замість паспорта повинен бути з двох сторін',
	cashDescription:
		'Ви можете виводити гроші на вже існуючі рахунки, або створити новий, натиснувши кнопку "додати новий рахунок"',
	addNewWayPay: 'Додати новий спосіб оплати',
	personInfo: 'Персональна інформація',
	editDescription: "Поля помічені зірочкою, обов'язкові для заповнення.",
	dragInDrop: 'Перетягніть сюди файл або',
	dragInDropImage: 'Перетягніть зображення сюди або',

	uploadFileWithPC: "завантажте з комп'ютера",
	reset: 'Скинути',
	refresh: 'Оновити',
	scanType: {
		PASSPORT: 'Паспорт',
		PASSPORT_PE:
			'Копія паспорта ФОП (всіх розворотів, де є записи і розвороту прописки навіть якщо його немає)',
		PASSPORT_OOO: 'Паспорти директора юр.лица (стор. 1-6, прописка і наступна сторінка за нею)',
		PASSPORT_OO: 'Паспорти директора юр.лица (стор. 1-6, прописка і наступна сторінка за нею)',

		INN: 'Ідентифікаційний номер',
		INN_PE: 'Копія ІПН ФОП',
		INN_OOO: 'ІПН директора юр. особи',
		INN_OO: 'ІПН директора юр. особи',

		REGISTRY_REPORT_PE: 'Виписка з Єдиного держ. Реєстр / Свідоцтво про державну реєстрацію ФОП',
		REGISTRY_REPORT_OOO: 'Виписки з ЄДР / свідоцтво про держ. реєстрацію юр.особи',
		REGISTRY_REPORT_OO: 'Виписки з ЄДР / свідоцтво про держ. реєстрацію юр.особи',

		BANK_REPORT_PE: 'Довідка з банку про відкриття поточного рахунку',
		BANK_REPORT_OOO:
			'Довідка про відкриття розрахункового рахунку з Банку (нового зразка із зазначенням IBAN)',
		BANK_REPORT_OO:
			'Довідка про відкриття розрахункового рахунку з Банку (нового зразка із зазначенням IBAN)',

		COMPANY_STATUTE_OOO: 'Статуту юр. особи',
		COMPANY_STATUTE_OO: 'Статуту юр. особи',
		ORG_STRUCTURE_OOO: 'Структура власності',
		ORG_STRUCTURE_OO: 'Структура власності',

		DIRECTOR_PROTOCOL_OOO: 'Протоколу, а також наказу про призначення директора',
		DIRECTOR_PROTOCOL_OO: 'Протоколу, а також наказу про призначення директора',
		FINANCE_REPORT_OO: 'Фінансовий звіт за рік',
		FONDY_CONTRACT: ''
	},

	verificationType: {
		PENDING: 'проходить верифікацію',
		CANCELED: 'не підтверджений',
		VERIFIED: 'підтверджений'
	},
	finance: {
		PENDING: 'Рахунок проходить перевірку, будь ласка зачекайте',
		CANCELED: "Рахунок не прийнятий, будь ласка зв'яжіться зі службою підтримки",
		VERIFIED: 'Рахунок прийнято',
		rs: 'р/р',
		paymentOnАccount: 'Оплата на р/р',
		paymentOnCard: 'Оплата на картку',
		companyName: 'Назва компанії, що приймає платежі',
		mfo: 'МФО',
		creditCard: 'Кредитна карта',
		edrpou: 'ЄДРПОУ',
		cardNumber: 'Введіть номер картки',
		bankAccountNumber: 'Номер розрахункового рахунку',
		uploadPassport: 'Завантажити скан паспорта (jpg, png)',
		uploadId: 'Завантажити скан ід. номера (jpg, png) ',
		uploadContract: 'Завантажити скан підписаного договору з fondy',
		naturalPersons: 'Фіз особа',
		naturalPersonsFull: 'Фізична особа',
		privateEntrepreneurFull: 'Фізична особа підприємець',
		oooFull: 'Товариство з обмеженою відповідальністю',
		ooFull: 'Громадська організація',
		privateEntrepreneur: 'ФОП',
		ooo: 'ТОВ',
		iban: 'IBAN',
		oo: 'ГО',

		ltd: 'ТОВ',
		statement: 'Скан виписки з єдиного реєстру',
		sertificateFromBank: 'Довідка з банку про відкриття рахунку'
	},
	addNewFinance: 'Додати новий рахунок',
	email: 'Email',
	firstName: "Ім'я",
	lastName: 'Прізвище',
	phone: 'Номер телефону',
	birthday: 'Дата народження',
	changePassword: 'Змінити пароль',
	oldPassword: 'Старий пароль',
	newPassword: 'Новий пароль',
	passwordChanged: 'Пароль успішно змінено.',
	myEvents: 'Мої події'
}
