import React, { useCallback, useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Loader from 'components/Loader'

import { updateEventInfo } from 'store/EventEdit/actions'
import { addNotification as addNotificationAction } from 'store/Notifications/actions'

import { WarningIcon } from '../Icons/Icons'

import s from './PreviewBlock.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)
const PreviewBlock = ({
	isPublished,
	canPublish,
	refetch,
	eventId,
	slug,
	locationName,
	address,
	isOnline
}) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const updateEventInfoField = useCallback(
		(field, value, id, slug) => dispatch(updateEventInfo(field, value, id, slug)),
		[dispatch]
	)
	const addNotification = useCallback((alert) => dispatch(addNotificationAction(alert)), [dispatch])
	const [t] = useTranslation()

	const changePublish = () => {
		setLoading(true)
		if (!isOnline && (!locationName || !address)) {
			addNotification({
				type: 'error',
				content: t('error.fillDateLocationName'),
				autoClose: 5000
			})
			setLoading(false)
			return
		}
		updateEventInfoField(
			'isPublished',
			!isPublished,
			eventId.length > 10 ? atob(eventId).replace(/\D/g, '') : eventId,
			slug
		)
			.then(() => {
				refetch()
			})
			.finally(() => {
				setLoading(false)
			})
	}
	return (
		<div className={cx('container', 'main', 'Content')}>
			{loading && (
				<div className={cx('wrap-loader-overlay')}>
					<Loader />
				</div>
			)}
			<WarningIcon width={25} height={25} />
			<div>
				<p>
					{t(`event.${isPublished ? 'wantToAddDraft' : 'wantToAddPublish'}`)}{' '}
					{canPublish && <span>{t(`event.${isPublished ? 'isDraftToAdd' : 'isPublish'}`)}</span>}
				</p>
				{canPublish && (
					<button onClick={changePublish} disabled={loading} className={'button-orange-light'}>
						{t(`event.${isPublished ? 'yesDraft' : 'yesPublish'}`)}
					</button>
				)}
			</div>
		</div>
	)
}
export default PreviewBlock
