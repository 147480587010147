import React, { useState } from 'react'

import { Trans, withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { useMutation } from '@apollo/client'
import { SUBSCRIBE } from 'apollo/mutations'

import IconAngle from 'components/Icons/Angle'
import Loader from 'components/Loader'

import s from './index.module.scss'

/**
 * Email newsletter subscription from
 */
const SubscribeForm = (props) => {
	const [subscribeRequest] = useMutation(SUBSCRIBE)
	const [loading, setLoading] = useState(false)
	const [email, setEmail] = useState('')
	const [text, setText] = useState('')
	const onSubmit = (e) => {
		e && e.preventDefault()
		/* Display loader  */
		setLoading(true)

		subscribeRequest({
			variables: { email }
		})
			.then(() => {
				/**
				 * Reset data, display success message
				 */
				setLoading(false)
				setEmail('')
				setText('subscribeForm.success')
			})
			.catch((e) => {
				const error = e.graphQLErrors[0] && e.graphQLErrors[0].message
				let text = 'error.'
				if (!error) return
				if (error === 'Subscribe with this Email already exists.') {
					text += 'You are already subscribed'
				} else {
					text += 'Enter a valid email address'
				}

				setLoading(false)
				setText(text)
			})
	}

	const onChangeEmail = (e) => {
		setEmail(e.target.value)
	}
	return (
		<form onSubmit={onSubmit} className={s.Form}>
			<h4 className={s.FormHeader}>
				<Trans i18nKey="subscribeForm.header" />
			</h4>
			<div className={s.FormControl}>
				<input placeholder="Email..." type="email" required value={email} onChange={onChangeEmail} />

				<button type="submit" disabled={loading}>
					{loading ? <Loader /> : <IconAngle />}
				</button>
			</div>
			<p>
				<Trans i18nKey={text} />
			</p>
		</form>
	)
}

export default compose(withTranslation())(SubscribeForm)
