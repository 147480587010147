import React from 'react'

export default function IconPin({ className = '' }) {
	return (
		<svg className={className} width="20" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.4 14.7c-4.1-6.2-4.9-6.8-4.9-9C4.5 2.4 7 0 10 0s5.5 2.5 5.5 5.6c0 2.3-.8 3-5 9.1-.2.4-.8.4-1 0zM10 8c1.3 0 2.3-1 2.3-2.4 0-1.3-1-2.3-2.3-2.3-1.3 0-2.3 1-2.3 2.3C7.7 7 8.7 8 10 8z"
				fill="#575757"
			/>
		</svg>
	)
}
