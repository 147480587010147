export default {
	name: 'Iм‘я',
	companyName: 'Назва компанії',
	phone: 'Номер телефону',
	password: 'Пароль',
	enter: 'Вхід',
	create: 'Створити',
	inputEmail: 'Введіть e-mail адресу який ви використовували при реєстрації:',
	needHelp: 'Мені потрібна допомога!',
	emailOrPhone: 'Email або номер телефону',
	inUp: 'Вхід/Реєстрація',
	out: 'Вихiд',
	up: 'Створення аккаунта',
	in: 'Виконайте вхід',
	orUp: 'Або зареєструйтеся з',
	orIn: 'Або увійдіть за допомогою',
	forgot: 'Відновлення пароля',
	forgotBtn: 'Забули пароль',
	rememberMe: "Запам'ятати мене",

	forgotSuccess:
		'Якщо ви зареєстровані на сайті, вам на пошту надіслано лист з посиланням для зміни пароля',
	email: 'Ваш email',
	slogan: 'Управління подіями і продаж квитків, як і повинно бути',
	recovery: 'Відновлення',
	enterNewPas: 'Введіть ваш новий пароль',
	endSign: 'Завершити реєстрацію',
	passwordReseted: 'Пароль успішно змінено',
	vereficationMessage: 'Вам надіслано код на номер телефону для підтвердження',
	iAgreeWith: 'Я погоджуюся з ',
	serviceTerms: 'умовами використання і політикою конфіденційності',
	dataProcessing: 'обробкою моїх персональних даних',
	createAccount: 'Немає облікового запису? створіть його',
	loginAccount: 'Вже є аккаунт? Увійдіть в нього',

	here: 'тут'
}
