import React, { memo, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import IconClose from 'components/Icons/Close'

import s from './Navbar.module.scss'
import classNames from 'classnames/bind'
import Button from 'pages/Landing/Button'
import LanguageSwitcher from 'pages/Landing/LanguageSwitcher/index'
import { logOut } from 'helpers/localStorage'

const cx = classNames.bind(s)

type Props = {
	close: any
	isOpen: boolean
	getToken: Function
	links?: React.ReactNode
	callback: Function
	redirect: Function
	onChange: any
}

function NavBar({ close, isOpen, getToken, links, callback, redirect, onChange }: Props) {
	let className: string = ''
	const [t] = useTranslation()
	const logOutCall = useCallback(() => {
		logOut().then(() => {
			redirect()
		})
	}, [])

	return (
		<div className={`${cx('Container')} ${isOpen ? s.Open : ''} ${className} header-custom`}>
			<LanguageSwitcher className={cx('LanguageSwitcher')} onChange={onChange} isShort={false} />
			<button className={cx('CloseBtn')} onClick={close}>
				<IconClose />
			</button>
			<nav className={cx('Nav')}>
				{!!getToken() ? (
					<>
						{links}
						<Button onClick={logOutCall}>
							<Trans i18nKey="sign.out" />
						</Button>
					</>
				) : (
					<Button onClick={callback}>
						<Trans i18nKey="event.createEvent" />
					</Button>
				)}
			</nav>
		</div>
	)
}

export default memo(NavBar)
