import React, { lazy, memo, Suspense, useEffect } from 'react'

import { connect } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import { STATIC_PATHNAMES } from 'constants'
import ModalPhoneVerification from 'containers/ModalPhoneVerification'
import ModalSign from 'containers/ModalSign/ModalSign'

import Notifications from 'components/Notifications'
import Preloader from 'components/Preloader'

import CheckIE from '../../components/CheckIE/CheckIE'
import { Wrapper } from './components/Wrapper'

import $ from 'helpers/dom'

import Dashboard from 'pages/Dashboard/Dashboard'
import Event from 'pages/Event'
import { NotFoundIllustation } from 'pages/Event/Icons/Icons'
import AgreementCookie from 'pages/Landing/AgreementCookie'
import NotFound from 'pages/NotFound/NotFound'
import Posters from 'pages/Posters'
import OwnPosters from 'pages/Posters/OwnPosters'

import { history } from 'store'
import { requestProfile } from 'store/Profile/actions'

const Profile = lazy(() => import('pages/Profile/Profile'))
const Search = lazy(() => import('pages/Search'))
const Landing = lazy(() => import('pages/Landing'))
const TicketActivation = lazy(() => import('pages/TicketActivation'))
const SignUp = lazy(() => import('pages/SignUp'))
const EventEdit = lazy(() => import('pages/EventEdit/EventEdit'))
const ResetPassword = lazy(() => import('pages/ResetPassword'))
const EmailVerification = lazy(() => import('pages/EmailVerification'))
const Terms = lazy(() => import('pages/Terms'))
const Static = lazy(() => import('pages/Static'))

function App(props) {
	useEffect(() => {
		props.requestProfile()
		const script = $.create('script', 'https://api.fondy.eu/static_common/v1/checkout/ipsp.js')
		document.body.appendChild(script)
		props.history.listen(() => {
			window.scrollTo(0, 0)
			window && window.Intercom && window.Intercom('update')
		})
	}, [])

	const { pathname } = props.history.location
	return (
		<Router history={history}>
			<div className="app">
				<ModalPhoneVerification />
				<ModalSign />
				<AgreementCookie />
				<CheckIE />
				<Notifications />
				<Suspense fallback={<Preloader />}>
					<Switch>
						<Route path="/" exact component={Posters} />
						<Route
							path="/dashboard"
							component={Wrapper({ Component: Dashboard, needToken: true, pathname: pathname })}
						/>
						<Route path="/all-events-from/:alias" component={OwnPosters} />
						<Route path="/search" component={Search} />
						<Route path="/organizers" component={Landing} />
						<Route path="/ticket-activation/:code" component={TicketActivation} />
						<Route
							path="/signup/:token"
							exact
							component={Wrapper({ Component: SignUp, needToken: false })}
						/>
						<Route path="/profile" component={Wrapper({ Component: Profile, needToken: true })} />
						<Route
							path="/event-edit/:slug"
							component={Wrapper({ Component: EventEdit, needToken: true })}
						/>
						<Route path="/event/:slug" exact component={Event} />
						<Route path="/event" component={Event} />
						<Route path="/create-event" component={Wrapper({ Component: EventEdit, needToken: true })} />
						<Route
							path="/reset-password/:id/:code"
							component={Wrapper({ Component: ResetPassword, needToken: false })}
						/>
						<Route path="/verify-email/:id/:code" component={EmailVerification} />
						{STATIC_PATHNAMES.map((path) => (
							<Route key={path} path={path} component={path === '/terms' ? Terms : Static} />
						))}
						<Route path="*">
							<NotFound>
								<NotFoundIllustation />
							</NotFound>
						</Route>
					</Switch>
				</Suspense>
			</div>
		</Router>
	)
}

export default connect(null, { requestProfile })(memo(App))
