export default (id, el) => {
	const element = el || document.getElementById(id)

	if (!element) return false

	const point = element.getBoundingClientRect().top - 100
	let start = null
	const V = 0.8 // Speed
	const offsetY = window.pageYOffset
	const step = (time) => {
		if (start === null) start = time
		let progress = time - start
		let r =
			point < 0
				? Math.max(offsetY - progress / V, offsetY + point)
				: Math.min(offsetY + progress / V, offsetY + point)
		window.scrollTo(0, r)
		if (r !== offsetY + point) {
			requestAnimationFrame(step)
		}
	}
	requestAnimationFrame(step)
}
