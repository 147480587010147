import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'

import s from './index.module.scss'

export default class Notification extends PureComponent {
	state = {
		close: false,
		timeoutId: undefined
	}

	static propTypes = {
		id: PropTypes.number.isRequired,
		content: PropTypes.any.isRequired,
		autoClose: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
		position: PropTypes.oneOf([
			'top-right',
			'top-left',
			'top-center',
			'bottom-right',
			'bottom-left',
			'bottom-center'
		]).isRequired,
		type: PropTypes.oneOf(['info', 'success', 'warning', 'error', 'default']).isRequired,
		closeOnClick: PropTypes.bool,
		hideProgressBar: PropTypes.bool,
		className: PropTypes.string,
		onClose: PropTypes.func,
		onOpen: PropTypes.func,
		onUpdate: PropTypes.func,
		style: PropTypes.object,
		removeNotification: PropTypes.func.isRequired
	}

	componentDidMount = () => {
		const { autoClose } = this.props
		if (autoClose) {
			this.setState({
				timeoutId: setTimeout(() => {
					this.hideNotification()
				}, autoClose)
			})
		}
	}

	hideNotification = () => {
		this.setState({ close: true })
		setTimeout(() => {
			this.props.removeNotification({ id: this.props.id, position: this.props.position })
		}, 1000)
	}

	render() {
		const { content, autoClose, className, type, closeOnClick, hideProgressBar } = this.props

		return (
			<div
				onClick={closeOnClick ? this.hideNotification : undefined}
				className={`${s.Notification} ${s[`Notification_${type}`]} ${this.state.close && s.Notification_close} ${className}`}
			>
				<button onClick={this.hideNotification} className={s.NotificationClose}>
					<i className="fa fa-times" aria-hidden="true" />
				</button>
				<div className={s.NotificationContent}>{content}</div>
				{autoClose && !hideProgressBar && (
					<div
						className={s.NotificationProgress}
						style={{
							animationPlayState: 'running',
							animationDuration: `${autoClose}ms`
						}}
					/>
				)}
			</div>
		)
	}
}
