import api from './api'
import error from './error'
import event from './event'
import footer from './footer'
import landing from './landing'
import languages from './languages'
import postersPage from './posters_page'
import searchPage from './search_page'
import sign from './sign'
import user from './user'

export default {
	sign,
	error,
	event,
	landing,
	user,
	languages,
	searchPage,
	postersPage,
	footer,
	whiteLabel: {
		title:
			'Відтепер ви можете отримати власну квиткову платформу <strong>за $99/міс або $999/рік</strong>.',
		readMore: 'Читати більше'
	},
	showBy: 'Показати по',
	show: 'Показати',
	copy: 'Скопіювати',
	copied: 'Скопійовано',
	aliasDescr1:
		'Ви можете вказати унікальний alias і давати посилання людям, в facebook і т.д. у вигляді:',
	aliasDescr2: 'Це зручно, так як люди будуть бачити тільки ваші події.',
	learnMore: 'Дізнатися більше',
	subscribeForm: {
		header: 'Отримуйте листи від teeko, щоб завжди бути в курсі подій!',
		success: 'Дякуємо за підписку 😁'
	},
	ticketActivation: {
		title: 'Активація квитків',
		ticketCreated: 'Квиток оформлено',
		order: 'Замовлення'
	},
	toHome: 'На головну',
	cancel: 'Відмінити',
	notifications: {
		smsSent: 'SMS успішно відправлено.',
		saved: 'Збережено',
		error: 'Сталася помилка!'
	},
	verify: {
		sendEmailAgain: 'Надіслати листа ще раз',
		verificatePhone: 'Підтвердити номер телефону',
		emailVerificated: 'Вітаємо, email підтверджено!',
		emailSended: 'Лист відправлений',
		email: 'Підтвердіть ваш email.',
		phone: 'Підтвердіть ваш номер телефону'
	},
	changeLang: 'Змінити мову',
	terms: {
		title: 'Правила користування',
		tabs: {
			organizer: 'Для організаторів',
			customer: 'Для покупців квитків'
		}
	},
	policy: {
		title: 'Політика конфіденційності'
	},
	cookie: {
		text:
			'При використанні teeko, ви погоджуєтесь з нашою політикою відносно файлів cookie. <0>Дізнатись більше.</0>',
		accept: 'Прийняти'
	},
	send: 'Відправити',
	remove: 'Видалити',
	download: 'Завантажити',
	weekDays: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'нд'],
	...api
}
