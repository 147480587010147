import React from 'react'

export default function IconSearch({ className = '' }) {
	return (
		<svg
			className={className}
			width="14"
			height="15"
			viewBox="0 0 14 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.8096 12.106L11.0832 9.37959C10.9602 9.25654 10.7934 9.18817 10.6184 9.18817H10.1726C10.9274 8.22287 11.3758 7.00872 11.3758 5.68792C11.3758 2.54589 8.82994 0 5.68792 0C2.54589 0 0 2.54589 0 5.68792C0 8.82994 2.54589 11.3758 5.68792 11.3758C7.00872 11.3758 8.22287 10.9274 9.18817 10.1726V10.6184C9.18817 10.7934 9.25654 10.9602 9.37959 11.0832L12.106 13.8096C12.363 14.0667 12.7787 14.0667 13.033 13.8096L13.8069 13.0357C14.0639 12.7787 14.0639 12.363 13.8096 12.106ZM5.68792 9.18817C3.75457 9.18817 2.18766 7.62399 2.18766 5.68792C2.18766 3.75457 3.75184 2.18766 5.68792 2.18766C7.62126 2.18766 9.18817 3.75184 9.18817 5.68792C9.18817 7.62126 7.624 9.18817 5.68792 9.18817Z"
				fill="white"
			/>
		</svg>
	)
}
