export default {
	organizerLanding: 'Kainos',
	company: 'Įmonė',
	aboutUs: 'Apie mus',
	team: 'komanda',
	career: 'Karjera',
	contacts: 'Kontaktai',
	resources: 'Organizatoriai',
	terms: 'Naudojimo sąlygos',
	policy: 'Privatumo politika',
	support: 'Žinių bazė',
	faq: 'DUK',
	telegram: 'Susisiekite su mumis per telegramą',
	cancellationPolicy: 'Atšaukimo ir pinigų grąžinimo politika',

	office: {
		title: 'Kontaktai',
		street: 'Khreshchatyk, 10 ',
		city: 'Kijevas, Ukraina, 01001',
		address: 'Kijevas, Khreshchatyk, 10'
	}
}
