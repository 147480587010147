import { gql } from '@apollo/client'
import {
	MULTILANGUAGE_LOCATION_NAME_EVENT_FIELDS,
	MULTILANGUAGE_NAME_EVENT_FIELDS
} from '../queries'

export default gql`
	query PosterEvents(
		$filter: PosterFilterInput
		$sort: PosterSortingEnum
		$before: String
		$after: String
		$first: Int
		$last: Int
		$withDescription: Boolean = false
	) {
		posterEvents(
			filter: $filter
			sort: $sort
			before: $before
			after: $after
			first: $first
			last: $last
		) {
			totalCount
			edges {
				node @include(if: $withDescription) {
					shortDescription
					id
					slug
					currency
					begins
					image
					timezoneField
					defaultLanguage
					isMultilanguage
					isOnline
					${MULTILANGUAGE_NAME_EVENT_FIELDS}
					${MULTILANGUAGE_LOCATION_NAME_EVENT_FIELDS}
					address
					prices
				}
				node @skip(if: $withDescription) {
					shortDescription
					id
					slug
					currency
					begins
					isOnline
					image
					timezoneField
					defaultLanguage
					isMultilanguage
					${MULTILANGUAGE_NAME_EVENT_FIELDS}
					${MULTILANGUAGE_LOCATION_NAME_EVENT_FIELDS}
					address
					prices
				}
			}
		}
	}
`
