import React, { memo } from 'react'

import s from './Banner.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

const Banner = ({ img }) => {
	const styles = {
		backgroundImage: `url(${img})`
	}
	return (
		<div className={'container'}>
			<div style={styles} className={cx('banner')}>
				<img src={img} alt="" />
			</div>
		</div>
	)
}
export default memo(Banner)
