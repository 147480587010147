import React, { PureComponent } from 'react'

import { Trans, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import client from 'apollo'
import { SEND_USER_EMAIL_AGAIN } from 'apollo/mutations'

class EmailNotVerify extends PureComponent {
	state = {
		emailSended: false
	}

	sendEmailAgain = () => {
		client
			.mutate({
				mutation: SEND_USER_EMAIL_AGAIN,
				variables: {
					id: this.props.userId
				}
			})
			.then((resp) => {
				if (resp.data.sendUserEmailAgain.ok) {
					this.setState({ emailSended: true })
				}
			})
	}

	render() {
		return (
			<div>
				<Trans i18nKey="verify.email" />{' '}
				{this.state.emailSended ? (
					<Trans className="btn-link" value="verify.emailSended" />
				) : (
					<button onClick={this.sendEmailAgain} className="btn-link">
						<Trans i18nKey="verify.sendEmailAgain" />
					</button>
				)}
			</div>
		)
	}
}

export default compose(
	withTranslation(),
	connect(({ Profile }) => ({ userId: Profile.id }))
)(EmailNotVerify)
