import React, { FC } from 'react'

import ReactTooltip from 'react-tooltip'

import s from './index.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

interface Props {
	address: string
	id: string
}

export const AddressOnline: FC<Props> = ({ address, id }) => (
	<>
		<p data-for={id} style={{ cursor: 'pointer' }} data-tip="">
			Online
		</p>
		<ReactTooltip
			id={id}
			className={cx('hideIpad', 'Tooltip')}
			type="success"
			effect="solid"
			place="bottom"
		>
			<h4>{address}</h4>
		</ReactTooltip>
		<div className={cx('showIpad')}>{address}</div>
	</>
)
