import React, { Component } from 'react'

import { Trans, withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { compose } from 'redux'

import client from 'apollo/'
import { CONFIRM_PHONE } from 'apollo/mutations'

import Loader from 'components/Loader'
import FieldError from 'components/UI/FieldError'

import s from './index.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(s)

class PhoneVerificationForm extends Component {
	static propTypes = {
		userId: PropTypes.string.isRequired,
		onSuccess: PropTypes.func,
		onError: PropTypes.func
	}

	state = {
		loading: false,
		code: {
			value: '',
			error: ''
		}
	}

	checkCode = (e) => {
		e && e.preventDefault()

		const { onSuccess, userId: id, onError } = this.props
		client
			.mutate({
				mutation: CONFIRM_PHONE,
				variables: {
					id,
					code: this.state.code.value
				}
			})
			.then((resp) => {
				if (resp.data.confirmPhone) {
					onSuccess && onSuccess()
				}
			})
			.catch(() => {
				this.setState({
					code: {
						value: this.state.code.value,
						error: 'incorrectSmsCode'
					},
					loading: false
				})
				onError && onError()
			})
	}

	render() {
		const { loading, code } = this.state
		return (
			<form className="phone-verification-form mb20" onSubmit={this.checkCode}>
				<label>
					<Trans i18nKey="sign.vereficationMessage" />
					<input
						onChange={(e) => {
							const value = e.target.validity.valid || e.target.value === '' ? e.target.value : code.value
							this.setState({ code: { value, error: '' } })
						}}
						value={code.value}
						required
						pattern="\d*"
						maxLength={4}
						placeholder="X X X X"
						className={`${code.error && 'border-danger'}`}
						type="text"
					/>
				</label>
				<FieldError error={code.error} />
				<button className={cx('button-orange', 'mt20', 'center')} disabled={loading}>
					{loading ? <Loader /> : <Trans i18nKey="sign.endSign" />}
				</button>
			</form>
		)
	}
}

export default compose(withTranslation())(PhoneVerificationForm)
