import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
	FacebookIcon,
	GmailIcon,
	IphoneIcon,
	OnlyLogo,
	PinIcon,
	TelegramIcon,
	UserIcon
} from '../Icons/Icons'

import s from './index.module.scss'
import classNames from 'classnames/bind'

import './slick-restyle.scss'

const cx = classNames.bind(s)
const Index = ({ organizers = [], description, eventLanguage }) => {
	let settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true
	}
	const [t] = useTranslation()
	description = description && description.replace(/href/g, 'target="_blank" href')
	return (
		<div className={cx('wrap-detail')}>
			<div className={cx('detail')}>
				<div
					className={cx('description-invent')}
					dangerouslySetInnerHTML={{ __html: description }}
				></div>
			</div>
			{organizers.length > 0 && (
				<Slider {...settings}>
					{organizers.map(
						({ node: { name, logo, phone, email, telegram, facebook, specialization } }, index) => {
							return (
								<div key={index} className={cx('rectangle', 'fz1628')}>
									<div className={cx('description-invent', 'rectangle-title')}>
										<UserIcon />
										<span>{t('event.organizerOf')}:</span>
									</div>
									<div className={cx('fz1628', 'fw600', 'name')}>{name || ''}</div>

									<div className={cx('img-ds')}>
										{logo ? <img src={logo || ''} alt="" /> : <OnlyLogo width={'197'} height={'100'} />}
									</div>

									<div className={cx('links')}>
										{facebook && (
											<a
												href={
													facebook.includes('http://www.facebook.com') ||
													facebook.includes('https://www.facebook.com')
														? `${facebook}`
														: `http://www.facebook.com/${facebook}`
												}
											>
												<FacebookIcon />
											</a>
										)}
										{telegram && (
											<a
												href={
													telegram.includes('https://telegram.me') || telegram.includes('http://telegram.me')
														? `${telegram}`
														: `https://telegram.me/${telegram}`
												}
											>
												<TelegramIcon />
											</a>
										)}
									</div>
									<div className={cx('fz1628', 'more')}>
										<div className={cx('description-invent')}>
											<IphoneIcon />
											<span>{t('event.phoneNumber')}</span>
										</div>
										<a href={`tel:${phone || ''}`} className={cx('fw600', 'c57')}>
											{phone || ''}{' '}
										</a>
										<div className={cx('description-invent')}>
											<GmailIcon fill={'#8C8C8C'} />
											<span> E-mail: </span>
										</div>
										<a href={`mailto:${email || ''}`} className={cx('fw600', 'c57')}>
											{email || ''}
										</a>
									</div>
									{specialization && (
										<div className={cx('block-bottom')}>
											<div className={cx('description-invent')}>
												<PinIcon />
												<span> {t('event.responseFor')}:</span>
											</div>
											<span>{specialization}</span>
										</div>
									)}
								</div>
							)
						}
					)}
				</Slider>
			)}
		</div>
	)
}
export default memo(Index)
