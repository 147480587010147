export default {
	long: {
		ru: 'Rusų',
		en: 'Anglų ',
		uk: 'Ukrainiečių',
		he: 'Hebrajų',
		pl: 'Lenkų',
		lv: 'Latvių',
		lt: 'Lietuvių'
	},
	short: {
		ru: 'Rus',
		en: 'Ang',
		uk: 'Ukr',
		he: 'Heb',
		pl: 'Len',
		lv: 'Lat',
		lt: 'Lietuvių'
	},
	/**
	 * remove
	 */
	ru: 'Rusų',
	en: 'Anglų',
	uk: 'Ukrainiečių',
	he: 'Hebrajų',
	pl: 'Lenkų',
	lv: 'Latvių',
	lt: 'Lietuvių'
}
