import React from 'react'

export default function IconCalendar({ className = '', color = '#575757' }) {
	return (
		<svg width="20" height="15" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.4 13.6c0 .8.7 1.4 1.4 1.4h10.4c.7 0 1.4-.6 1.4-1.4v-8H3.4v8zm9.4-5.7c0-.2.2-.4.4-.4h1.1c.2 0 .4.2.4.4V9c0 .2-.2.4-.4.4h-1.1a.4.4 0 0 1-.4-.4V8zm0 3.7c0-.2.2-.3.4-.3h1.1c.2 0 .4.1.4.3v1.2c0 .2-.2.3-.4.3h-1.1a.4.4 0 0 1-.4-.3v-1.2zM9.1 7.9c0-.2.1-.4.3-.4h1.2c.2 0 .3.2.3.4V9c0 .2-.1.4-.3.4H9.4a.4.4 0 0 1-.3-.4V8zm0 3.7c0-.2.1-.3.3-.3h1.2c.2 0 .3.1.3.3v1.2c0 .2-.1.3-.3.3H9.4a.4.4 0 0 1-.3-.3v-1.2zM5.3 7.9c0-.2.2-.4.4-.4h1.1c.2 0 .4.2.4.4V9c0 .2-.2.4-.4.4H5.7a.4.4 0 0 1-.4-.4V8zm0 3.7c0-.2.2-.3.4-.3h1.1c.2 0 .4.1.4.3v1.2c0 .2-.2.3-.4.3H5.7a.4.4 0 0 1-.4-.3v-1.2zm9.9-9.7h-1.4V.5c0-.3-.3-.5-.5-.5h-1c-.2 0-.4.2-.4.5v1.4H8V.5C8.1.2 8 0 7.7 0h-1c-.2 0-.5.2-.5.5v1.4H4.8c-.7 0-1.4.6-1.4 1.4v1.4h13.2V3.3c0-.8-.7-1.4-1.4-1.4z"
				fill={color}
			/>
		</svg>
	)
}
