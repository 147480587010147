import React from 'react'

export default function IconInstagram() {
	return (
		<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.7 16.9V8.5H16c.2.5.2 1 .2 1.7 0 1-.2 2-.8 3a6.3 6.3 0 0 1-5.4 3 6 6 0 0 1-4.4-1.8 5.7 5.7 0 0 1-1.5-6H2.2V17l.3.6.5.2h14c.2 0 .3 0 .5-.2s.2-.4.2-.6zm-3.7-7c0-1-.4-2-1.1-2.7A4 4 0 0 0 10 6.1a4 4 0 0 0-2.8 1.1C6.4 8 6 9 6 10c0 1 .4 2 1.2 2.7A4 4 0 0 0 10 14a4 4 0 0 0 2.9-1.2C13.6 12 14 11 14 10zm3.7-4.6V3c0-.2 0-.4-.2-.6a.9.9 0 0 0-.7-.3h-2.2c-.3 0-.5.1-.7.3l-.2.6v2.2c0 .2 0 .4.2.6.2.2.4.3.7.3h2.2c.3 0 .5-.1.7-.3l.2-.6zM20 2.6v14.8c0 .7-.3 1.3-.8 1.8s-1 .8-1.8.8H2.6c-.7 0-1.3-.3-1.8-.8s-.8-1-.8-1.8V2.6C0 1.9.3 1.3.8.8s1-.8 1.8-.8h14.8c.7 0 1.3.3 1.8.8s.8 1 .8 1.8z"
				fill="#575757"
			/>
		</svg>
	)
}
