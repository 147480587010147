export default {
	RESET: '@@profile/RESET',
	SET_USER: '@@profile/SET_USER',
	SET_TOKEN: '@@profile/SET_TOKEN',
	EDIT: '@@profile/EDIT',
	REQUEST_PROFILE: '@@profile/REQUEST_PROFILE',
	REQUEST_PROFILE_CANCELED: '@@profile/REQUEST_PROFILE_CANCELED',
	ERROR_REQUEST_PROFILE: '@@profile/ERROR_REQUEST_PROFILE',
	USER_DATA_REQUESTED: '@@profile/USER_DATA_ALREADY_REQUESTED'
}
