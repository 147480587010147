export default {
	financeDeleted: 'Finanšu konts dzēsts',
	financeNotDeleted: 'Finanšu konts nav dzēsts',
	eventIsEmpty: 'To wydarzenie nie istnieje lub nie zostało opublikowane przez organizatora',
	ups: 'Diemžēl ..... ',
	somethingWrong: 'kaut kas nogāja greizi',
	integrationMinLength: 'Kods ir pārāk īss. Minimālajam rakstzīmju skaitam jābūt lielākam par 5',
	inputFinance: 'Ievadiet finanšu informāciju',
	slugExists: 'Šī lode jau pastāv',
	enterSlug: 'Enter slug',
	prevSlugNotAvailable: 'Lūdzu, ņemiet vērā, ka vecā saite uz pasākumu nebūs pieejama.',
	'User with this Alias already exists': 'Lietotājs ar šo aizstājvārdu jau pastāv.',

	'slug exists': 'Diemžēl jūs nevarat saglabāt šo plēvi, tā jau ir aizņemta',

	fillDateLocationName: 'Aizpildiet norises vietas nosaukumu un adresi',
	fillAllDateLocationName: 'Aizpildiet norises vietas nosaukumu un adresi visās valodās',
	attemptsError:
		'Autorizācijas mēģinājumu skaits ir pārsniegts. Pēc dažām sekundēm mēģiniet vēlreiz',
	'Please add phone number': 'Lūdzu, pievienojiet tālruņa numuru',
	'Please upload image': 'Lūdzu, augšupielādējiet notikuma attēlu',

	'Organizer with this Name already exists': 'Kontaktpersonas vārds ar šādu nosaukumu jau pastāv',
	'Organizer with this Email already exists': 'Kontaktpersona ar šo e-pasta adresi jau pastāv',
	'Organizer with this Phone already exists': 'Kontaktpersona ar šo tālruņa numuru jau pastāv',
	incorrectSmsCode: 'kods ir nepareizs vai ir beidzies',
	emailVerificateError:
		'Atvainojiet, bet apstiprinājuma saite vairs nav aktīva vai jau ir aktivizēta. Mēģiniet vēlreiz. ',
	resetPasswordCodeError: 'Atvainojiet, bet saite vairs nav aktīva. Mēģiniet vēlreiz.',
	'Field is required': 'Obligāts lauks',
	'Invalid credantails': 'Nederīgi akreditācijas dati',
	'Invalid phone number': 'Nederīgs tālruņa numurs',
	'Enter a valid email address': 'Nederīga e-pasta adrese',
	'Password must have at least 8 characters': 'Parolē jābūt vismaz 8 rakstzīmēm',
	'User with this Email already exists': 'Lietotājs ar šo e-pasta adresi jau pastāv',
	'User with this Phone already exists': 'Lietotājs ar šo tālruņa numuru jau pastāv',
	'You are already subscribed': 'Jūs jau esat abonējis',
	'Incorrect old password': 'Nederīga vecā parole',
	'Incorrect coupon': 'Nepareizs reklāmas kods',
	"End date can't be later than event's end date":
		'Beigu datums nevar būt vēlāks par notikuma beigu datumu (% {date})',
	"Start date can't be after end date": 'Sākuma datums nevar būt vēlāks par beigu datumu',
	'You cannot set start date in the past': 'Jūs nevarat iestatīt sākuma datumu pagātnē',
	'You cannot set end date in the past': 'Jūs nevarat iestatīt beigu datumu pagātnē',
	'You cannot set end date before start': 'Jūs nevarat iestatīt beigu datumu sākuma datumam',
	'Something went wrong, contact support':
		'Kaut kas noieta nepareizi. Lūdzu, sazinieties ar atbalsta dienestu.',
	'Incorrect code': 'Nederīgs kods. Lūdzu, vēlreiz skenējiet QR kodu',
	'You have not access, contact organizer':
		'Jums nav atļaujas aktivizēt biļeti. Lūdzu, sazinieties ar organizatoru',
	'Attendee status is invalid': 'Nevar aktivizēt biļeti. Biļetei ir statuss: ',
	'You can not added role to yourself': 'Jūs nevarat piešķirt lomu sev',
	'Invite already send': 'Uzaicinājums jau ir nosūtīts',
	'Select available languages': 'Lūdzu, atlasiet pieejamās valodas',
	'Error occurred, check fields': ' Radusies kļūda. Lūdzu, pārbaudiet ievadītos datus.',
	'No more tickets': 'Vairs nav biļešu',

	'Coupon is inactive': 'Reklāmas kods nav aktīvs',
	'Please fill in all fields': 'Lūdzu, aizpildiet visus laukus',
	'Please add days': 'Lūdzu, pievienojiet dienu',
	'Please fill in all fields all lang': 'Lūdzu, aizpildiet visus laukus visām valodām',
	'Password contains invalid symbol': 'Parolē ir nederīgas rakstzīmes',
	'Please fill mandatory fields for main lang':
		'Lūdzu, aizpildiet obligātos laukus galvenajai valodai'
}
