import error from './error'
import event from './event'
import footer from './footer'
import landing from './landing'
import languages from './languages'
import postersPage from './posters_page'
import searchPage from './search_page'
import sign from './sign'
import user from './user'

export default {
	sign,
	error,
	event,
	landing,
	user,
	languages,
	searchPage,
	postersPage,
	footer,
	'Pay as a legal entity': 'Mokėti kaip juridiniam asmeniui',
	'Send an invoice by mail': 'Sąskaitą atsiųsk paštu',
	'Information about order': 'Informacija apie užsakymą',
	'Billing address': 'Atsiskaitymo adresas',
	email: 'El. paštas',
	address: 'Adresu',
	'An error occurred while paying for the event': 'Mokant už įvykį įvyko klaida.',
	'Please try again later': 'Pabandykite dar kartą vėliau.',
	city: 'Miestas',
	country: 'Šalis',
	firstName: 'Pirmas vardas',
	lastName: 'Pavardė',
	zip: 'Pašto kodas',
	'Max length': 'Maksimalus ilgis: {{chars}} simboliai',
	'Sign in as a event visitor': 'Prisijungti kaip renginio lankytojas',
	'Sign in as a organizer': 'Prisijungti kaip renginio organizatorius',
	'Add to calendar': 'Pridėti į kalendorių',
	'Upcoming events': 'Būsimieji renginiai',
	'Past events': 'Praeities renginiai',
	'Type to search': 'Įveskite paieškos terminą',
	'Registration Form': 'Registracijos forma',
	'You can add KEY-WORD in your letter': 'Savo laiške galite įtraukti KEY-WORD',
	'Add a KEY-WORD for all the events': 'Pridėti raktinį žodį visiems renginiams',
	'Add a KEY-WORD for this ticket': 'Pridėkite raktinį žodį prie šio bilieto',
	'Buy a ticket only if you have a promotional code':
		' Nusipirkti bilietą tik turėdami nuolaidos kodą',
	'Season ticket': 'Sezono bilietas',
	'Upload file': 'Įkelti failą',
	'Add rows': 'Pridėti eilutes',
	'All tickets': 'Visi bilietai',
	'Deactivation date': 'Išjungimo data',
	'Sold tickets': 'Parduoti bilietai',
	'Show the ticket': 'Parodyti bilietą',
	'confirm participation': 'Patvirtinti dalyvavimą',
	"Can't come": 'Negalėsiu atvykti',
	'My tickets': 'Mano bilietai',
	'Log out': 'Atsijungti',
	'Amount of rows': 'Eilučių kiekis',
	'Row from number': 'Eilė iš numerio',
	'To row': 'Irkluoti',
	'Please enter information about the number of seats in a row':
		'Įveskite informaciją apie vietų skaičių iš eilės',
	'Row number': 'Eilės numeris',
	'Places from number': 'Vietos iš skaičiaus',
	'To number': 'Į numerį',
	'Copy properties': 'Kopijuoti ypatybes',
	Rows: 'Eilutės',
	Seat: 'Sėdynės',
	'Įmonės pavadinimas': 'Įmonės pavadinimas',
	'Mokėkite kaip juridinis asmuo (įveskite savo atsiskaitymo informaciją)':
		'Mokėkite kaip juridinis asmuo (įveskite savo atsiskaitymo informaciją)',
	'Mokėti kaip juridiniam asmeniui': 'Mokėti kaip juridiniam asmeniui',
	'Atsisiųsti sąskaitą faktūrą': 'Atsisiųsti sąskaitą faktūrą',
	Apmokėta: 'Apmokėta',
	Neapmokėta: 'Neapmokėta',
	Sum: 'Suma',
	'Date created': 'Data created',
	'Number of tickets': 'Bilietų skaičius',
	Status: 'Statusas',
	'Iš naujo siųsti sąskaitą faktūrą': 'Iš naujo siųsti sąskaitą faktūrą',
	'Pažymėti kaip apmokėtą': 'Pažymėti kaip apmokėtą',
	'Pažymėti kaip neapmokėtą': 'Pažymėti kaip neapmokėtą',

	goFuck: 'Rusijos karo laivas, eikite į dulkes',
	helpArmy: 'Padėti Ukrainos kariuomenei',
	showBy: 'Rodyti pagal',
	show: 'Rodyti',
	learnMore: 'Sužinoti daugiau',
	copy: 'Kopijuoti',
	copied: 'Kopijuoti',
	aliasDescr1:
		'Galite nurodyti unikalų slapyvardį ir pateikti nuorodas žmonėms, facebook ir t. t. kaip:',
	aliasDescr2: 'Tai patogu, nes žmonės matys tik jūsų renginius.',
	subscribeForm: {
		header: 'Gaukite laiškus iš satchel.events, kad gautumėte naujienas!',
		success: 'Ačiū, kad užsiprenumeravote 😁'
	},
	ticketActivation: {
		title: 'Bilieto aktyvavimas',
		ticketCreated: 'Išduotas bilietas',
		order: 'Bilietas:'
	},
	toHome: 'Pagrindinis',
	cancel: 'Atšaukti',
	notifications: {
		smsSent: 'SMS išsiųsta sėkmingai.',
		saved: 'Išsaugota',
		error: 'Įvyko klaida!'
	},
	verify: {
		sendEmailAgain: 'Dar kartą siųsti el. laišką',
		verificatePhone: 'Patikrinti telefono numerį',
		emailVerificated: 'Sveikiname, el. laiškas patvirtintas!',
		emailSended: 'Elektroninis laiškas išsiųstas',
		email: 'Patvirtinkite savo el. paštą.',
		phone: 'Patvirtinkite savo telefono numerį'
	},
	changeLang: 'Keisti kalbą',
	terms: {
		title: 'Paslaugų teikimo sąlygos',
		tabs: {
			organizer: 'Organizatoriams',
			customer: 'Pirkėjams'
		}
	},
	policy: {
		title: 'Privatumo politika'
	},
	cookie: {
		text:
			'Naudodamiesi satchel.events, jūs sutinkate su mūsų slapukų politika. <0> Sužinokite daugiau. </ 0> ',
		accept: 'Priimti'
	},
	send: 'siųsti',
	remove: 'Pašalinti',
	download: 'Atsisiųsti',
	weekDays: ['Pr', 'An', 'Tr', 'Kt', 'Pn', 'Št', 'Sk']
}
