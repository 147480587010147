import React from 'react'

import { Link, NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
/**
 * Maybe may sence to rewrite it with this flow:
 *
 * 1) if url with anchor === curren url -> scroll
 * 2) if url with anchor !== current url -> navigate to needed url and there check hash and element
 *
 * Think about 10 secound for observer
 */
let hashFragment = ''
let observer = null
let asyncTimerId = null
let scrollFunction = null

function reset() {
	hashFragment = ''
	if (observer !== null) observer.disconnect()
	if (asyncTimerId !== null) {
		window.clearTimeout(asyncTimerId)
		asyncTimerId = null
	}
}

function getElAndScroll() {
	const element = document.getElementById(hashFragment)
	if (element !== null) {
		scrollFunction(element)
		reset()
		return true
	}
	return false
}

function hashLinkScroll() {
	// Push onto callback queue so it runs after the DOM is updated
	window.setTimeout(() => {
		if (getElAndScroll() === false) {
			if (observer === null) {
				observer = new MutationObserver(getElAndScroll)
			}
			observer.observe(document, {
				attributes: true,
				childList: true,
				subtree: true
			})
			// if the element doesn't show up in 10 seconds, stop checking
			asyncTimerId = window.setTimeout(() => {
				reset()
			}, 10000)
		}
	}, 0)
}

export function genericHashLink(props, As) {
	function handleClick(e) {
		reset()
		if (props.onClick) props.onClick(e)
		if (typeof props.to === 'string') {
			hashFragment = props.to.split('#').slice(1).join('#')
		} else if (typeof props.to === 'object' && typeof props.to.hash === 'string') {
			hashFragment = props.to.hash.replace('#', '')
		}
		if (hashFragment !== '') {
			scrollFunction =
				props.scroll ||
				((el) => (props.smooth ? el.scrollIntoView({ behavior: 'smooth' }) : el.scrollIntoView()))
			hashLinkScroll()
		}
	}
	const { scroll, smooth, ...filteredProps } = props
	return (
		<As {...filteredProps} onClick={handleClick}>
			{props.children}
		</As>
	)
}

export function HashLink(props) {
	return genericHashLink(props, Link)
}

export function NavHashLink(props) {
	return genericHashLink(props, NavLink)
}

const propTypes = {
	onClick: PropTypes.func,
	children: PropTypes.node,
	scroll: PropTypes.func,
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

HashLink.propTypes = propTypes
NavHashLink.propTypes = propTypes
